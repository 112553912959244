import { useRef, useEffect, useState } from "react";
import { Parallax } from "react-parallax";
import "./IvoryCoastHeroSection.css";
import { Link } from "react-scroll";
import HeroBackground from "../../../../media/images/muslim_woman_studying_1.jpg";
import HeroBackgroundSmall from "../../../../media/images/muslim_woman_studying_1.jpg";

export default function IvoryCoastHeroSection() {
  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      console.log("resizing");
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const backgroundImage =
    windowWidth > 720 ? HeroBackground : HeroBackgroundSmall;
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  });

  return (
    <>
      <Parallax
        blur={0}
        bgImage={backgroundImage}
        bgImageStyle={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
        bgImageAlt="Hero Section"
        strength={400}
        className={`parallax-image-container ${
          isSectionIntersected ? "visible" : ""
        }`}
        style={{ transition: "all 2s ease-in-out" }}
      >
        <section ref={sectionRef} className="ivory-coast-hero-section-wrapper">
          <div className="ivory-coast-hero-section-container">
            <div className="ivory-coast-hero-section-image-container"></div>
            <div
              className={`ivory-coast-hero-section-body ${
                isSectionIntersected ? "visible" : ""
              }`}
            >
              <div className="ivory-coast-hero-section-titles-container">
                <h1 className="ivory-coast-hero-section-title">Wyze Africa</h1>
                <h1 className="ivory-coast-hero-section-subtitle">
                  Côte d'Ivoire
                </h1>
              </div>
              <h4 className="ivory-coast-hero-section-slogan">
                Explore Create Innovate
              </h4>
              <a
                href="#ivory-coast-trainings-section"
                className="ivory-coast-hero-section-btn"
              >
                <span>Découvrir nos formations</span>
              </a>
              {/* <Link
                to="ivory-coast-trainings-section"
                spy={true}
                smooth={true}
                offset={-70} // Adjust this offset as needed to fit your layout
                duration={500}
                className="ivory-coast-hero-section-btn"
              >
                <span>Découvrir nos formations</span>
              </Link> */}
            </div>
          </div>
        </section>
      </Parallax>
    </>
  );
}
