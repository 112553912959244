import "./Tabs.css";
import React from "react";

const Tabs = ({ defaultValue, children, additionalClassNames }) => {
  const [activeTab, setActiveTab] = React.useState(defaultValue);

  const classNames = additionalClassNames
    ? `tabs-container ${additionalClassNames}`
    : "tabs-container";

  const renderTabsList = () => {
    return React.Children.map(children, (child) => {
      if (child.type === TabsList) {
        return React.cloneElement(child, { activeTab, setActiveTab });
      }
      return null;
    });
  };

  const renderTabsContent = () => {
    return React.Children.map(children, (child) => {
      if (child.type === TabsContent) {
        return React.cloneElement(child, { activeTab });
      }
      return null;
    });
  };

  return (
    <div className={classNames}>
      {renderTabsList()}
      {renderTabsContent()}
    </div>
  );
};

const TabsList = ({ children, activeTab, setActiveTab }) => {
  return (
    <div className="tabs-list">
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          isActive: child.props.value === activeTab,
          onClick: () => setActiveTab(child.props.value),
        });
      })}
    </div>
  );
};

const TabsTrigger = ({ value, isActive, onClick, children }) => {
  return (
    <button
      className={`tab ${isActive ? "tab-selected" : ""}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const TabsContent = ({ value, activeTab, children }) => {
  return activeTab === value ? (
    <div className="tab-content">{children}</div>
  ) : null;
};

export { Tabs, TabsList, TabsTrigger, TabsContent };
