import React from "react";
import "./Contact.css";
import Contact from "../../../../components/Contact/Contact";

export default function ContactSection() {
  return (
    <section id="contact-section" className="contact-wrapper">
      <Contact />
    </section>
  );
}
