import "./ProgramOverview.css";
import Strie from "../../../../components/Strie/Strie";
import "./StriesAdjustments.css";
import useIntersectionObserver from "../../../../Hooks/useIntersectionObserver";
import LinkButton from "../../../../components/LinkButton/LinkButton";

export default function ProgramOverview() {
  const [checklistRef, isChecklistVisible] = useIntersectionObserver(0.2);
  return (
    <>
      <div className="tunisia-program-overview-section-wrapper">
        <div className="tunisia-program-overview-section-container">
          <div className="tunisia-program-overview-section-content">
            <div className="section-badge">Programme</div>
            <h2 className="tunisia-program-overview-section-title">
              Qu'est-ce que vous allez apprendre ?
            </h2>
            <div className="tunisia-program-overview-section-description">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Perferendis sapiente in nisi nulla aliquam quaerat sit rem,
                itaque iure est.{" "}
              </p>
              <p>
                Exercitationem est et inventore culpa placeat libero incidunt
                expedita ea. Perferendis sapiente in nisi nulla aliquam quaerat
                sit rem, itaque iure est. Exercitationem est et inventore culpa
                placeat libero incidunt expedita ea. Perferendis sapiente in
                nisi nulla aliquam quaerat sit rem, itaque iure est.
                Exercitationem est et inventore culpa placeat libero incidunt
                expedita ea.
              </p>
            </div>
            <div className="tunisia-program-overview-section-button-container">
              {/* <button className="tunisia-program-overview-section-button">
                S'inscrire
              </button> */}

              <LinkButton to="/tunisia/program" text={"S'inscrire"} />
            </div>
          </div>
          <div
            ref={checklistRef}
            className="tunisia-program-overview-section-checklist-container"
          >
            <h6 className="tunisia-program-overview-checklist-title">
              Aperçu du programme
            </h6>
            <div className="tunisia-program-overview-section-checklist">
              <div
                // className="tunisia-program-overview-section-checklist-item"
                className={`tunisia-program-overview-section-checklist-item ${
                  isChecklistVisible ? "visible" : ""
                }`}
                id="tunisia-program-overview-checklist-item-1"
              >
                {/* <div
                  className={`tunisia-program-overview-checklist-item-icon ${
                    isChecklistVisible ? "visible" : ""
                  }`}
                > */}
                <CheckIcon width={18} height={18} />
                {/* </div> */}
                <div className="tunisia-program-overview-section-checklist-item-text">
                  Fondamentaux de l'entrepreneuriat
                </div>
              </div>
              <div
                className={`tunisia-program-overview-section-checklist-item ${
                  isChecklistVisible ? "visible" : ""
                }`}
                id="tunisia-program-overview-checklist-item-2"
              >
                {/* <div
                  className={`tunisia-program-overview-checklist-item-icon ${
                    isChecklistVisible ? "visible" : ""
                  }`}
                 
                > */}
                <CheckIcon width={18} height={18} />
                {/* </div> */}
                <div className="tunisia-program-overview-section-checklist-item-text">
                  Dévéloppement Web
                </div>
              </div>
              <div
                className={`tunisia-program-overview-section-checklist-item ${
                  isChecklistVisible ? "visible" : ""
                }`}
                id="tunisia-program-overview-checklist-item-3"
              >
                <CheckIcon width={18} height={18} />
                <div className="tunisia-program-overview-section-checklist-item-text">
                  Planification stratégique et opérationnelle
                </div>
              </div>
              <div
                className={`tunisia-program-overview-section-checklist-item ${
                  isChecklistVisible ? "visible" : ""
                }`}
                id="tunisia-program-overview-checklist-item-4"
              >
                <CheckIcon width={18} height={18} />
                <div className="tunisia-program-overview-section-checklist-item-text">
                  Maîtriser le pitching et le financement
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Strie
          scale={12}
          color="orange-africa"
          left="100%"
          top="0%"
          id="tunisia-section-1-top-right-strie-1"
          zindex={0}
        /> */}
        <Strie
          scale={2.5}
          color="orange-africa"
          left="95%"
          top="10%"
          id="tunisia-program-overview-top-right-strie-1"
        />
        <Strie
          scale={7}
          color="orange-africa"
          left="95%"
          top="40%"
          id="tunisia-program-overview-top-right-strie-2"
        />
      </div>
    </>
  );
}

function CheckIcon({ width = 24, height = 24 }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        height={height}
        width={width}
        version="1.1"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
        style={{ fill: "#000000", minHeight: height, minWidth: width }}
      >
        <g>
          <path
            d="M469.402,35.492C334.09,110.664,197.114,324.5,197.114,324.5L73.509,184.176L0,254.336l178.732,222.172   l65.15-2.504C327.414,223.414,512,55.539,512,55.539L469.402,35.492z"
            fill="#000000"
          />
        </g>
      </svg>
    </>
  );
}
