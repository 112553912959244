import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// Importez vos traductions manuellement
import arTranslations from "../locales/ar/translation.json";
import enTranslations from "../locales/en/translation.json";
import frTranslations from "../locales/fr/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      fr: {
        translation: frTranslations,
      },
      en: {
        translation: enTranslations,
      },
      ar: {
        translation: arTranslations,
      },
    },
    fallbackLng: "en", // langue de secours
    lng: "fr", // langue par défaut
    interpolation: {
      escapeValue: false, // React gère déjà l'échappement des valeurs
    },
  });

export default i18n;
