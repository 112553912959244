import "../../App.css";
import "./AlgeriaPage.css";
import ContentSection1 from "./sections/ContentSection1/ContentSection1";
import ContentSection2 from "./sections/ContentSection2/ContentSection2";
import FeaturesSectionV1 from "./sections/FeaturesSectionV1/FeaturesSectionV1";
import HeroSectionV1 from "./sections/HeroSectionV1/HeroSectionV1";
import IntroSectionV1 from "./sections/IntroSectionV1/IntroSectionV1";
import ProgramOverview from "./sections/ProgramOverviewSection/ProgramOverview";
// import TrainingsSectionV4 from "./sections/TrainingsSectionV4/TrainingsSectionV4";
import Footer from "../../components/Footer/Footer";
import TestimonialsSection from "./sections/TestimonialsSection/TestimonialsSection";
import TrainingsSectionV5 from "./sections/TrainingsSectionV5/TrainingsSectionV5";
// import InfoTabsSectionV1 from "./sections/InfoTabsSectionV1/InfoTabsSectionV1";
import pageData from "../pageData.json";

import InfoTabsSectionV2 from "./sections/InfoTabsSectionV2/InfoTabsSectionV2";

export default function AlgeriaPage() {
  const { features, sources } = pageData.pageAlgeria;
  const algeriaData = pageData.pageAlgeria; // Spécifique à l'Algérie

  return (
    <>
      <div className="algeria-page">
        <HeroSectionV1 />
        <IntroSectionV1 />
        <InfoTabsSectionV2
          features={features}
          sources={sources}
          pageData={algeriaData}
        />
        <ContentSection1 />
        <ContentSection2 />
        {/* <TrainingsSectionV4 /> */}
        {/* <InfoTabsSectionV1 /> */}
        <ProgramOverview />
       { /*<TrainingsSectionV5 />*/}
        <TestimonialsSection />

        <FeaturesSectionV1 />

   
      </div>
    </>
  );
}
