import "./TabTitleListBtn.css";
import { themeColors } from "../../utils/themeColors";

export default function TabTitleListBtn({ btnText, hoverFillColor }) {
  return (
    <>
      <div
        to="/"
        className="cta"
        style={{ "--hover-fill-color": hoverFillColor }}
      >
        <span>{btnText}</span>
      </div>
    </>
  );
}
