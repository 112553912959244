import React, { useEffect, useState } from "react";
import useIntersectionObserver from "../../../Hooks/useIntersectionObserver";
// import "./AnimateOnScrollWrapper.css";

export default function AnimateOnScrollWrapper({
  children,
  baseMobileDelay = 0, // in seconds
  baseDesktopDelay = 0.5, // in seconds
  mobileIndexDelayMultiplier = 0.8, // in seconds
  desktopIndexDelayMultiplier = 0.5, // in seconds
  additionalClassNames = "",
}) {
  const [ref, isIntersecting] = useIntersectionObserver(0.1);
  const [isMobile, setIsMobile] = useState(false);

  // Detect if the screen is mobile
  useEffect(() => {
    const checkScreenSize = () => {
      // console.log("window size changed");
      setIsMobile(window.innerWidth <= 768); // Adjust the width as per your design breakpoints
    };

    window.addEventListener("resize", checkScreenSize);
    checkScreenSize(); // Initial check

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    // console.log("isMobile: ", isMobile);
  }, [isMobile]);

  return (
    <>
      <div ref={ref} className={`${additionalClassNames}`}>
        {React.Children.map(children, (child, index) => {
          // Calculate the delay based on the index and screen size
          const delay = isMobile
            ? `${baseMobileDelay + index * mobileIndexDelayMultiplier}s`
            : `${baseDesktopDelay + index * desktopIndexDelayMultiplier}s`;
          // console.log("delay for index: ", index, " is : ", delay);
          return React.cloneElement(child, {
            triggerAnimation: isIntersecting,
            delay: delay,
          });
        })}
      </div>
    </>
  );
}
