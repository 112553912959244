import React, { useState, useEffect } from "react";
import "./Formationv2.css";
import formationData from "./../formationData.json";  // Assurez-vous de créer et remplir ce fichier avec les données de formation

const importAll = (requireContext) => requireContext.keys().reduce((acc, next) => {
  acc[next.replace('./', '')] = requireContext(next);
  return acc;
}, {});

const images = importAll(require.context("../../../../media/images/", false, /\.(png|jpe?g|svg)$/));

export default function FormationSection() {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Charger les données directement depuis le fichier JSON
    setData(formationData);  // Assurez-vous que formationData est un tableau de formations
  }, []);

  if (data.length === 0) return <div>Loading...</div>;

  return (
    <section className="section-formation-senegal">
        <h2>Formation</h2>
      <div className="section-formation-container-senegal">
        {data.map((formation, index) => (
          <FormationCard key={index} formation={formation} />
        ))}
      </div>
    </section>
  );
}

function FormationCard({ formation }) {
  return (
    <div className="formation-card-senegal">
      <img
        src={images[formation.image]}
        alt={formation.title}
        className="formation-image-senegal"
      />
      <div className="formation-content-senegal">
        <h3 className="formation-title-senegal">{formation.title}</h3>
        <p className="formation-description-senegal">{formation.description}</p>
        <button className="formation-button-senegal">En savoir plus</button>
      </div>
    </div>
  );
}
