import React from "react";
import Footer from "../../components/Footer/Footer";
import "./MaliPage.css";
import MentorSection from "./sections/MentorSection";
import PitchCompetition from "./sections/PitchCompetition";
import Section from "./sections/Section";
import Skills from "./sections/Skills";
// import Strie from "../../components/Strie/Strie";
import pageData from "../pageData.json";
import Banner from "./sections/Banner";
import Formaton from "./sections/Formation";

import InfoTabsSectionV2 from "../Algeria/sections/InfoTabsSectionV2/InfoTabsSectionV2";
export default function MaliPage() {
  const { features, sources } = pageData.pageMali;

  return (
    <>
      <div className="mali-page" style={{ overflow: "hidden" }}>
        <Banner />

        <InfoTabsSectionV2 features={features} sources={sources} />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <PitchCompetition />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Section />
        </div>

        <div style={{ marginTop: "100px" }}>
          <Formaton />
        </div>

        <div
          style={{
            marginTop: "100px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MentorSection />
        </div>
        <div style={{ marginTop: "100px" }}>
          <Skills />
        </div>

       
      </div>
    </>
  );
}
