import { Helmet } from "react-helmet";
import "./HomePage.css";
import AboutUsSection from "./sections/about-us-section/AboutUsSection";
import ContactSection from "./sections/contact-section/Contact";
// import FeaturedSection from "./sections/featured-section/FeaturedSection";
import FaqSection from "./sections/faq-section/FaqSection";
// import HeroSection from "./sections/hero-section/HeroSection";
import IntroSection from "./sections/intro-section/IntroSectionV2";
import OurTrainingsSectionV6 from "./sections/our-trainings-section/OurTrainingsSectionV6";
// import CreateStartupSection from "./sections/create-startup-section/CreateStartupSection";
import CreateStartupSection from "./sections/create-startup-section/CreateStartupSectionV3";
import HeroSectionV2 from "./sections/hero-section/HeroSectionV2";
// import AdulteSection from "./sections/adulte-section/AdulteSection";
import AdulteSection from "./sections/adulte-section/AdulteSectionV2";
// import KidsSection from "./sections/kids-section/KidsSection";
// import KidsSection from "./sections/kids-section/KidsSectionV2";
// import AfricaSection from "./sections/africa-section/AfricaSection";
import AfricaSection from "./sections/africa-section/AfricaSectionV2";
// import AvisSection from "./sections/avis-section/AvisSection";
import AvisSection from "./sections/avis-section/AvisSection2";
import VideoSection from "./sections/video-section/VideoSection";

export default function HomePage() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WyzeAcademy Africa</title>
      </Helmet>
      <div className="home-page-wrapper">
        <HeroSectionV2 />
        <IntroSection />
        {/* <KidsSection /> */}
        <AdulteSection />
        <AfricaSection />
        <AboutUsSection />
        <CreateStartupSection />
        <OurTrainingsSectionV6 />
        <VideoSection />
        <AvisSection />
        <FaqSection />
        <ContactSection />
      </div>
    </>
  );
}
