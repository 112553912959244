import AnimateOnScroll from "../../../../components/Animations/AnimateOnScroll/AnimateOnScroll";
import AnimateOnScrollWrapper from "../../../../components/Animations/AnimateOnScrollWrapper/AnimateOnScrollWrapper";
import Strie from "../../../../components/Strie/Strie";
import AlgeriaBgImage from "../../../../media/images/algeria-1.jpg";
import KidBackground from "../../../../media/images/kid-formation-background.jpg";
import StartupImage from "../../../../media/images/startup-4.jpeg";
import "./OurTrainingsSectionV6.css";
import "./StriesAdjustments.css";

export default function OurTrainingsSectionV6() {
  return (
    <>
      <section id="formation-section" className="trainings-section-wrapper">
        <div className="trainings-section-title-container">
          <h2 className="trainings-section-title">Nos Formations</h2>
        </div>
        <AnimateOnScrollWrapper
          baseMobileDelay={0}
          baseDesktopDelay={0.5}
          mobileIndexDelayMultiplier={0.5}
          desktopIndexDelayMultiplier={0.5}
          additionalClassNames="trainings-section-cards-container"
        >
          <AnimateOnScroll
            animationClass="bounce-in-left"
            delay="0s"
            duration="1s"
          >
            <TrainingCard
              title="Formation stratégie digitale"
              link="/NosFormation"
              backgroundImage={StartupImage}
              additionalClassNames="bg-hover-harvest-gold-variant-strong"
            />
          </AnimateOnScroll>
          <AnimateOnScroll
            animationClass="bounce-in-right"
            delay="500ms"
            duration="1s"
          >
            <TrainingCard
              title="Création d'un site e-Commerce"
              link="/Nosformation"
              backgroundImage={AlgeriaBgImage}
              additionalClassNames="bg-hover-harvest-gold-variant-strong"
              // additionalClassNames="bg-hover-harvest-gold-variant-light"
            />
          </AnimateOnScroll>
          <AnimateOnScroll
            animationClass="bounce-in-left"
            delay="750ms"
            duration="1s"
          >
            <TrainingCard
              title="Package All-in-One"
              subtitle="Découvrir nos formations"
              link="/NosFormation"
              backgroundImage={KidBackground}
              additionalClassNames="bg-hover-harvest-gold-variant-strong"
              // additionalClassNames="bg-hover-safety-orange-variant-strong"
            />
          </AnimateOnScroll>
        </AnimateOnScrollWrapper>
        <Strie
          scale={3}
          // color="light-harvest-gold"
          color="green-green"
          left="-5%"
          top="0%"
          zindex={-1}
          id="trainings-section-top-left-strie-1"
        />
        <Strie
          scale={1.5}
          // color="light-harvest-gold"
          color="green-green"
          left="-5%"
          top="15%"
          zindex={-1}
          id="trainings-section-top-left-strie-2"
        />
        <Strie
          scale={1.5}
          // color="brown-beige"
          color="orange-africa"
          // color="brown"
          left="95%"
          top="85%"
          zindex={-1}
          id="trainings-section-bottom-right-strie-1"
        />
        <Strie
          scale={3}
          // color="brown-beige"
          // color="orange-africa"
          color="brown"
          left="100%"
          top="95%"
          zindex={-1}
          id="trainings-section-bottom-right-strie-2"
        />
      </section>
    </>
  );
}

function TrainingCard({
  title,

  link,
  backgroundImage,
  additionalClassNames = "",
}) {
  const style = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
  };

  return (
    <div
      className={`${additionalClassNames} training-card-item background-container`}
      style={style}
    >
      <div className="training-card-content-container">
        <a href={link}>
          <h3 className="training-card-title">{title}</h3>
        </a>
      </div>
    </div>
  );
}
