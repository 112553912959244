import "./IntroSectionCard.css";

function IntroSectionCard({ icon, title, icon_width, children }) {
  return (
    <div className={`card-container`}>
      <div>
        <img src={icon} alt={icon} width={icon_width}></img>
      </div>

      <div style={{height:"80px"}}>
        <h6 className="semi-bold card-container-title">{title}</h6>
      </div>
      <div>
        <p className="card-container-description">{children}</p>
      </div>
    </div>
  );
}

export default IntroSectionCard;
