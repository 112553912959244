import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import icon from "../../media/images/icon.webp";
// Import d'une icône d'exemple
import arabicFlag from "../../media/images/langue-ar.png";
import ukFlag from "../../media/images/langue-en.png";
import franceFlag from "../../media/images/langue-fr.png";
import "./LanguageSwitcher.css";

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false); // État pour contrôler la visibilité du menu

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsOpen(false); // Ferme le menu après le changement de langue
  };

  return (
    <div className="language-switcher">
      <button
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Select language"
        style={{ backgroundColor: "transparent" }}
      >
        <img
          src={icon}
          alt="icon"
          style={{ height: "45px", marginTop: "-27px" }}
        />
      </button>{" "}
      {isOpen && (
        <div className="language-dropdown">
          <button onClick={() => changeLanguage("ar")}>
            <img src={arabicFlag} alt="العربية" className="dropdown-img" />
          </button>
          <button onClick={() => changeLanguage("en")}>
            <img
              src={ukFlag}
              alt="English"
              width="40"
              height="24"
              className="dropdown-img"
            />
          </button>
          <button onClick={() => changeLanguage("fr")}>
            <img
              src={franceFlag}
              alt="Français"
              width="40"
              height="24"
              className="dropdown-img"
            />
          </button>
        </div>
      )}
    </div>
  );
}
