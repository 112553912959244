// sections/PitchCompetition.jsx
import React, { useState, useEffect } from "react";
import "./PitchCompetition.css";
import pitchCompetitionData from "./pitchCompetitionData.json";

// Importer les images dynamiquement
const importAll = (requireContext) => requireContext.keys().reduce((acc, next) => {
  acc[next.replace('./', '')] = requireContext(next);
  return acc;
}, {});

const images = importAll(require.context("../../../media/images", false, /\.(png|jpe?g|svg)$/));

export default function PitchCompetition() {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Charger les données directement depuis le fichier JSON
    setData(pitchCompetitionData[0]); // Utiliser la première section pour cet exemple
  }, []);

  if (!data) return <div>Loading...</div>;

  return (
    <section className="pitch-competition-mali">
      <div className="pitch-competition-container-mali">
        <div className="pitch-competition-illustration-mali">
          <img
            src={images[data.image]}
            alt="Pitch Competition-mali"
            className="pitch-competition-image-mali"
          />
        </div>
        <div className="pitch-competition-content-mali">
          <h2 className="pitch-competition-title-mali">
            {data.title}
          </h2>
          <p className="pitch-competition-description-mali">
            {data.description}
          </p>
          <button className="apply-button-mali">Apply Now</button>
        </div>
      </div>
    </section>
  );
}
