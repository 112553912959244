import Footer from "../../components/Footer/Footer";
import "./GuineaConakryPage.css";
import GuineaConakryHeroSection from "./sections/GuineaConakryHeroSection/GuineaConakryHeroSection";

export default function GuineaConakryPage() {
  return (
    <>
      <GuineaConakryHeroSection />
  
    </>
  );
}
