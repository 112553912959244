import { Link } from "react-router-dom";
import AlgeriaBackground from "../../../../media/images/algeria.webp";
import Dakar from "../../../../media/images/Dakar.jpg";
import IcoryCoastYamoussoukro from "../../../../media/images/IcoryCoastYamoussoukro.webp";
import MaliBamako from "../../../../media/images/MaliBamako.jpg";
import MoroccoKoutoubiaMosque from "../../../../media/images/MoroccoKoutoubiaMosque.webp";
import "./CreateStartupSectionV3.css";

export default function CreateStartupSection() {
  return (
    <>
      <div className="create-startup-section-wrapper">
        <div className="create-startup-section-header-container">
          <h2 className="create-startup-section-title">
            Créer votre startup !
          </h2>
        </div>

        <div className="create-startup-section-container">
          <CountryImageCard
            cardIdNbr={1}
            image={AlgeriaBackground}
            title={"Algérie"}
            redirectUrl="/formations/algerie"
            subtitle={"Découvrir +"}
            additionalClasses="create-startup-image"
          />

          <CountryImageCard
            cardIdNbr={2}
            image={MoroccoKoutoubiaMosque}
            title={"Maroc"}
            redirectUrl="/formations/maroc"
            subtitle={"Découvrir +"}
            additionalClasses="create-startup-image"
          />
          <CountryImageCard
            cardIdNbr={3}
            image={IcoryCoastYamoussoukro}
            title={"Côte d'Ivoire"}
            redirectUrl="/formations/cote-d-ivoire"
            subtitle={"Découvrir +"}
            additionalClasses="create-startup-image"
          />

          <CountryImageCard
            cardIdNbr={4}
            image={Dakar}
            title={"Sénégal"}
            redirectUrl="/formations/senegal"
            subtitle={"Découvrir +"}
            additionalClasses="create-startup-image"
          />

          <CountryImageCard
            cardIdNbr={5}
            image={MaliBamako}
            title={"Mali"}
            redirectUrl="/formations/mali"
            subtitle={"Découvrir +"}
            additionalClasses="create-startup-image"
          />
        </div>
      </div>
    </>
  );
}

function CountryImageCard({
  cardIdNbr,
  image,
  title,
  subtitle,
  redirectUrl,
  additionalClasses = "",
}) {
  const classNames = `${additionalClasses}`;
  const id = `card-${cardIdNbr}`;

  return (
    <>
      <div
        id={id}
        href="/"
        className="create-startup-image-card"
        // style={{ backgroundImage: `url(${image})`, cursor: "pointer" }}
      >
        <Link
          to={redirectUrl}
          className="create-startup-image-card"
          style={{
            backgroundImage: `url(${image})`,
            cursor: "pointer",
            display: "flex",
            height: "100%",
          }}
        >
          <div className="create-startup-card-container">
            <div className="create-startup-card-container-header">
              <h3 className="create-startup-card-container-title bold">
                {title}
              </h3>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}
