import React, { useEffect, useState } from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import CartElement from "../../components/CartElements/CartElements";
import Footer from "../../components/Footer/Footer";
import Strie from "../../components/Strie/Strie";
import emptyIcon from "../../media/icons/ecommerce-icon.png";
import paypalogo from "../../pages/PaymentConfirmation.jsx/validation-image/paypal_logo.png";
import "./cartPage.css";

function Cart() {
  const navigate = useNavigate();
  const [cart, setCart] = useState(
    secureLocalStorage.getItem("training-card")
      ? JSON.parse(secureLocalStorage.getItem("training-card"))
      : []
  );

  const [total, setTotal] = useState(0);

  const [isChecked, setIsChecked] = useState(false);
  // Update total when cart changes
  useEffect(() => {
    const calculateTotal = cart.reduce(
      (acc, curr) => acc + Number(curr.prix || 0),
      0
    );
    setTotal(calculateTotal);
    secureLocalStorage.setItem("training-card", JSON.stringify(cart));
  }, [cart]);

  const handleCheckbox = (e) => {
    setIsChecked(e.target.checked);
  };

  const deleteItem = (id) => {
    const updatedCart = cart.filter((item) => item.id !== id);
    setCart(updatedCart);
    secureLocalStorage.setItem("training-card", JSON.stringify(updatedCart));
  };

  const updateCart = (updatedCart) => {
    setCart(updatedCart);
  };

  const ProceedPayement = () => {
    if (!isChecked) return;
    secureLocalStorage.setItem("training-card_total", total);
    navigate("/validation", { state: { cart, total } });
  };
  console.log("cart cart..............", cart);

  if (cart.length === 0) {
    return (
    
      <section className="cart-empty-container">
        <div className="cart-text">
          <img src={emptyIcon} className="cart-empty-img" alt="empty-cart" />
          <p className="cart-empty-text">
            {" "}
            Panier Vide. Essayez d'ajouter une formation.
          </p>
          <Link className="cart-empty-link" to="/#formation-section">
            <IoArrowBackCircleOutline
              size={28}
              className="icon"
              color="#97179c"
              />{" "}
            Continuer Vos Achats
          </Link>
        </div>
      </section>
    );
  }

  return (
    <main className="cart">
      <div className="cart-wrapper">
        <div className="cart-container">
          <div className="cart-grid">
            <section className="panier">
              <h2>Panier</h2>
              <div className="elements-cart-container">
                {cart.map((item) => {
                  console.log(item); // Pour voir les données
                  return (
                    <CartElement
                    key={item.id}
                    element={item}
                    deleteItem={deleteItem}
                    updateCart={updateCart}
                    />
                  );
                })}
              </div>

              <div className="push-to-bottom">
                <Link to={"/#formation-section"} className="link">
                  <IoArrowBackCircleOutline
                    size={28}
                    className="icon"
                    color="#97179c"
                    />{" "}
                  Continuer Vos Achats
                </Link>
              </div>
            </section>

            <section className="total">
              <div>
                <h3>
                  Total
                  <span className="sum">
                    {total.toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </span>
                </h3>

                <div>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckbox}
                    />
                  <label>
                    Je suis d'accord avec les termes et les conditions
                  </label>
                </div>
                {isChecked && cart.length > 0 ? (
                  <button onClick={ProceedPayement} className="btn">
                    Passer Au Payement
                  </button>
                ) : (
                  <button className="btn disabled-btn" disabled>
                    Passer Au Payement
                  </button>
                )}

                <p>Nous Acceptons</p>
                <img src={paypalogo} />
              </div>
            </section>
          </div>
        </div>
      </div>
      <Strie scale={1} color="blue" top="60%" left="-4%" zindex={-1} />
      <Strie scale={2} color="blue" top="70%" zindex={-1} left="-2%" />
      <Strie
        scale={2}
        color="red"
        left="unset"
        top="10%"
        zindex={-1}
        right="-2%"
      />
      <Strie
        scale={1}
        color="red"
        left="unset"
        right="-4%"
        top="20%"
        
        zindex={-1}
      />

    </main>
  );
}

export default Cart;
