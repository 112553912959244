import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Formation.css";
import Strie from "../../../components/Strie/Strie";

import FormationImg from "../../../media/images/IA_formation.jpg";

function FormationSection() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const data = [
    {
      name: `Introduction to Programming`,
      img: FormationImg,
      review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    },
    {
      name: `Web Development Bootcamp`,
      img: FormationImg,
      review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    },
    {
      name: `Data Science`,
      img: FormationImg,
      review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    },
    {
      name: `Mobile App Development`,
      img: FormationImg,
      review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    },
    {
      name: `Cloud Computing Essentials`,
      img: FormationImg,
      review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    },
  ];

  return (
    <div className="formation-mali-body">
      <div className="mali-container">
        <div className="formation-header-mali">
          <h2>Formation</h2>
        </div>
        <div className="mali-carousel-container">
          <Slider {...settings}>
            {data.map((d) => (
              <div key={d.name} className="mali-card">
                <div className="mali-card-image-container">
                  <img src={d.img} alt={d.name} className="mali-card-image" />
                </div>
                <div className="mali-card-content">
                  <p className="mali-card-title">{d.name}</p>
                  <p className="mali-card-review">{d.review}</p>
                  <button className="mali-card-button">Read More</button>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <Strie scale={5} color="red" top="20%" left="-16%" zIndex={-1} />
      <Strie scale={2} color="red" left="-16%" top="50%" zIndex={-1} />
      <Strie scale={4} color="blue" left="114%" top="60%" zIndex={-1} />
      <Strie scale={2} color="blue" left="114%" top="37%" />

      </div>
    </div>
  );
}

export default FormationSection;
