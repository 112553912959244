import "./AboutUsSectionIllustration.css";

export default function AboutUsSectionIllustration({ image }) {
  return (
    <>
      <div className="about-us-section-illustration">
        <img
          src={image}
          alt="About Us"
          className="about-us-section-image"
          style={{ filter: "brightness(0.93)" }}
        />
      </div>
    </>
  );
}
