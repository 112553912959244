import "./AvisCard.css";

function AvisCard({ picture, description, name, stars, area }) {
  const renderStars = (stars) => {
    const starElements = [];
    for (let i = 0; i < 5; i++) {
      if (i + 1 <= stars) {
        starElements.push(
          <span key={i} className="star filled">
            ★
          </span>
        );
      } else {
        starElements.push(
          <span key={i} className="star">
            ☆
          </span>
        );
      }
    }
    return starElements;
  };

  return (
    <div className={`avis-card-container avis-card-${area}`}>
      <img
        className="avis-card-container-picture"
        src={picture}
        alt={picture}
      ></img>
      <div className="avis-card-container-body">
        <div className="avis-section-stars">{renderStars(stars)}</div>
        <p className="avis-card-container-description">{description}</p>
        <div className="avis-card-details">
          <p className="semi-bold">{name}</p>
        </div>
      </div>
    </div>
  );
}

export default AvisCard;
