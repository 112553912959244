import { useRef, useEffect, useState } from "react";

export default function useIntersectionObserver(threshold = 0) {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          console.log("setting isVisible to true");
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: threshold }
    );
    observer?.observe(ref?.current);
    return () => observer.disconnect();
  }, []);

  return [ref, isVisible];
}
