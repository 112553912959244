import React from "react"
import "./courses.css"
import { online } from "../../dummydata"
import Heading from "../common/heading/Heading"
import img from "../../../../media/images/ux_black.png"
import img1 from "../../../../media/images/o1.1.png"

import Strie from "../../../../components/Strie/Strie";

const OnlineCourses = () => {
  return (
    <>
      <section className='online-senegal'>
        <div className='container-senegal'>
          <Heading subtitle='Formation' title=' Lorem ipsum dolor sit amet consectetur adipisicing elit.' />
          <div className='content-senegal grid3'>
            {online.map((val) => (
              <div className='box-senegal'>
                <div className='img-senegal'>
                  <img src={img} />
                  <img src={img1} alt='' className='show-senegal' />
                </div>
                <h1>{val.courseName}</h1>
                <span>{val.course}</span>
              </div>
            ))}
          </div>
          <Strie scale={5} color="red" top="400%" left="-7%" zIndex={-1} />
  <Strie scale={2} color="red" left="-6%" top="430%" zIndex={-1} />
  <Strie scale={4} color="blue" left="98%" top="400%" zIndex={-1} />
  <Strie scale={2} color="blue" left="98%" top="373%" />
        </div>
      </section>
    </>
  )
}

export default OnlineCourses
