import TrainingCardV2 from "../../../../components/TrainingCardV2/TrainingCardV2";
import { themeColors } from "../../../../utils/themeColors";
import useIntersectionObserver from "../../../../Hooks/useIntersectionObserver";
import Strie from "../../../../components/Strie/Strie";
import "./TrainingsSection.css";
import "./StriesAdjustments.css";
import "./Animations.css";

export default function TrainingsSection() {
  const [trainingsRef, isTrainingsVisible] = useIntersectionObserver(0.2);

  return (
    <>
      <section
        className="tunisia-trainings-section--wrapper"
        id="tunisia-trainings-section"
      >
        <div className="tunisia-trainings-section--header">
          <div className="tunisia-trainings-section--header-badge">
            Nos formations
          </div>
          <h2 className="tunisia-trainings-section--header-title">
            Ce qu'on a à vous offrir
          </h2>
          <p className="tunisia-trainings-section--header-description">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa
            repellendus blanditiis, quae necessitatibus saepe soluta voluptate
            beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor
            sit amet, consectetur adipisicing elit. Nisi ipsa repellendus
            blanditiis, quae necessitatibus saepe soluta voluptate beatae,
            commodi, esse libero dolores asperiores.
          </p>
        </div>
        <div
          ref={trainingsRef}
          // className="tunisia-trainings-section--container"
          className={`tunisia-trainings-section--container ${
            isTrainingsVisible ? "visible" : ""
          }`}
        >
          <TrainingCardV2
            id={1}
            title={"Introduction à l'entrepreneuriat"}
            ctaBtnText={"En savoir plus"}
            itemColor={themeColors.haverstGold}
            btnHoverFillColor={themeColors.safetyOrange}
          >
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa
            repellendus blanditiis, quae necessitatibus saepe soluta voluptate
            beatae, commodi, esse libero dolores asperiores.
          </TrainingCardV2>
          <TrainingCardV2
            id={2}
            title={"Dévéloppement Web"}
            ctaBtnText={"En savoir plus"}
            itemColor={themeColors.safetyOrange}
            btnHoverFillColor={themeColors.haverstGold}
          >
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa
            repellendus blanditiis, quae necessitatibus saepe soluta voluptate
            beatae, commodi, esse libero dolores asperiores.
          </TrainingCardV2>
          <TrainingCardV2
            id={3}
            title={"Marketing Digital"}
            ctaBtnText={"En savoir plus"}
            itemColor={themeColors.selectiveYellow}
            btnHoverFillColor={themeColors.safetyOrange}
          >
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa
            repellendus blanditiis, quae necessitatibus saepe soluta voluptate
            beatae, commodi, esse libero dolores asperiores.
          </TrainingCardV2>
          <TrainingCardV2
            id={4}
            title={"Fondamentaux du business"}
            ctaBtnText={"En savoir plus"}
            // itemColor={themeColors.bardRed}
            btnHoverFillColor={themeColors.bardRed}
          >
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa
            repellendus blanditiis, quae necessitatibus saepe soluta voluptate
            beatae, commodi, esse libero dolores asperiores.
          </TrainingCardV2>
          <TrainingCardV2
            id={5}
            title={"Mentorat et Networking"}
            ctaBtnText={"En savoir plus"}
            itemColor={themeColors.pakistanGreen}
            btnHoverFillColor={themeColors.pakistanGreen}
          >
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa
            repellendus blanditiis, quae necessitatibus saepe soluta voluptate
            beatae, commodi, esse libero dolores asperiores.
          </TrainingCardV2>
          <TrainingCardV2
            id={6}
            title={"Pitching et Financement"}
            ctaBtnText={"En savoir plus"}
          >
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa
            repellendus blanditiis, quae necessitatibus saepe soluta voluptate
            beatae, commodi, esse libero dolores asperiores.
          </TrainingCardV2>
        </div>
        <Strie
          scale={5.5}
          color="green-green"
          left="0%"
          top="10%"
          id="tunisia-trainings-top-left-strie-1"
        />
        <Strie
          scale={3}
          color="green-green"
          left="0%"
          top="20%"
          id="tunisia-trainings-top-left-strie-2"
        />
        <Strie
          scale={3}
          color="brown"
          left="100%"
          top="75%"
          id="tunisia-trainings-bottom-right-strie-1"
        />
        <Strie
          scale={6}
          color="orange-africa"
          left="100%"
          top="90%"
          id="tunisia-trainings-bottom-right-strie-2"
        />
      </section>
    </>
  );
}
