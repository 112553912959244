import StartupImage from "../../../../media/images/startup-4.jpeg";
import "./IntroSection.css";
import Strie from "../../../../components/Strie/Strie";
import "./StriesAdjustments.css";
import useIntersectionObserver from "../../../../Hooks/useIntersectionObserver";
import LinkButton from "../../../../components/LinkButton/LinkButton";

export default function IntroSection() {
  const [ref, isVisible] = useIntersectionObserver(0.3);
  return (
    <>
      <section ref={ref} className="tunisia-intro-section-wrapper">
        <div className="tunisia-intro-section-container">
          <div
            className={`tunisia-intro-section-content ${
              isVisible ? "visible" : ""
            }`}
          >
            <h2 className="tunisia-intro-section-title">
            Encourager les startups tunisiennes à prospérer
            </h2>
            <div className="tunisia-intro-section-description">
            Le Tunisia Startup Program est une initiative complète conçue pour soutenir et accélérer la croissance des entreprises entrepreneuriales en Tunisie. Avec un accent particulier sur le développement web, le marketing digital et d'autres domaines clés des affaires, nous nous 
            engageons à aider les startups à atteindre leur plein potentiel.
            </div>
            <div className="tunisia-intro-section-buttons-container">
              {/* <div className="tunisia-intro-section-button">Apply now</div> */}
              {/* <div className="tunisia-intro-section-button">Learn more</div> */}
              <LinkButton to="/tunisia/apply-now" text="S'inscrire" />
              <LinkButton
                to="/tunisia/program-overview"
                text="En savoir plus"
              />
            </div>
          </div>
          <div
            className={`tunisia-intro-section-illustration ${
              isVisible ? "visible" : ""
            }`}
          >
            <img
              src={StartupImage}
              alt="Introduction section image"
              className="tunisia-intro-section-image"
            />
          </div>
        </div>
        <Strie
          scale={5}
          color="green-green"
          top="10%"
          left="-5%"
          zindex={2}
          id="tunisia-intro-section-top-left-strie-1"
        />
        <Strie
          scale={5}
          color="orange-africa"
          top="60%"
          left="100%"
          zindex={2}
          id="tunisia-intro-section-bottom-right-strie-1"
        />
      </section>
    </>
  );
}
