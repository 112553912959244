import "../../../../css/colors.css";
import "./AboutUsSection.css";
import "./StriesAdjustments.css";
// import testImage from "../../../../media/images/hero_image.jpg";
import bgImage from "../../../../media/images/hero_image_8.jpeg";
// import bgImage from "../../../../media/images/happy-man-1.webp";
import Strie from "../../../../components/Strie/Strie";
import AboutUsContextBox from "./Components/AboutUsContextBox";
import "./Components/AboutUsContextBox.css";
import AboutUsSectionIllustration from "./Components/AboutUsSectionIllustration";
import "./Components/AboutUsSectionIllustration.css";

export default function AboutUsSection() {
  return (
    <>
      <div className="about-us-section-wrapper">
        <div className="about-us-section-container">
          <AboutUsSectionIllustration image={bgImage} />
          <AboutUsContextBox title={""} animationDelay={"0s"}>
            <p>
              <p>
                Rassurez-vous, Nous vous accompagnpns dans le lancement de votre
                business dans l'un des cinq pays suivants : <br />{" "}
                <span className="bold ">
                  Mali, Côte d'Ivoire, Maroc, Sénégal et Algérie.
                </span>
              </p>

              {/* Nos formations <span className="bold ">All in One</span> vous
              permettront de <span className="bold ">créer votre boîte</span>,
              de la <span className="bold ">promouvoir</span> sur les réseaux et
              de <span className="bold ">créer le site web</span>. Nous vous{" "}
              <span className="bold ">accompagnerons</span> de la naissance
              jusqu’à vos premiers gains. */}
              {/* Nos formations{" "}
              <span className="bold">All in One</span> vous permettront de{" "}
              <span className="bold">créer votre boîte</span>, de la{" "}
              <span className="bold ">promouvoir</span> sur les réseaux et de{" "}
              <span className="bold">créer le site web</span>. Nous vous{" "}
              <span className="bold">accompagnerons</span> de la naissance
              jusqu’à vos premiers gains. Nos formations{" "} */}
              {/* <span className="bold">All in One</span> vous permettront de{" "}
              <span className="bold">créer votre boîte</span>, de la{" "}
              <span className="bold">promouvoir</span> sur les réseaux et de{" "}
              <span className="bold">créer le site web</span>. Nous vous{" "}
              <span className="bold">accompagnerons</span> de la naissance
              jusqu’à vos premiers gains.{" "}
              <span className="bold">All in One</span> vous permettront de{" "}
              <span className="bold">créer votre boîte</span>, de la{" "}
              <span className="bold">promouvoir</span> sur les réseaux et de{" "}
              <span className="bold">créer le site web</span>. Nous vous{" "}
              <span className="bold">accompagnerons</span> de la naissance
              jusqu’à vos premiers gains. Nos formations{" "}
              <span className="bold">All in One</span> vous permettront de{" "}
              <span className="bold">créer votre boîte</span>, de la{" "}
              <span className="bold">promouvoir</span> sur les réseaux et de{" "}
              <span className="bold">créer le site web</span>. Nous vous{" "}
              <span className="bold">accompagnerons</span> de la naissance
              jusqu’à vos premiers gains.{" "} */}
            </p>
          </AboutUsContextBox>
        </div>
        <Strie
          scale={5}
          color="green-green"
          top="0%"
          left="-5%"
          zindex={-1}
          id="about-us-section-top-left-strie-1"
        />
        <Strie
          scale={2}
          color="green-green"
          top="30%"
          left="-2%"
          zindex={-1}
          id="about-us-section-top-left-strie-2"
        />
        <Strie
          scale={6}
          color="orange-africa"
          top="140%"
          left="100%"
          zindex={-1}
          id="about-us-section-bottom-right-strie-2"
        />
        <Strie
          scale={2}
          color="brown"
          top="105%"
          left="96%"
          zindex={-1}
          id="about-us-section-bottom-right-strie-1"
        />
      </div>
    </>
  );
}
