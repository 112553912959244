import React from "react"
import Heading from "../../common/heading/Heading"
import "./Hero.css"

const Hero = () => {
  return (
    <>
      <section className='hero-senegal'>
        <div className='container-senegal'>
          <div className='row-senegal'>
            <Heading subtitle='' title='Wyze Africa Senegal' />
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos doloribus magnam voluptatem nulla possimus atque tenetur,</p>
            <div className='.button-senegal'>
              <button className='primary-btn-senegal button-se'>
                Voir plus <i className='fa fa-long-arrow-alt-right'></i>
              </button>

            </div>
          </div>
        </div>
      </section>
      <div className='margin'></div>
    </>
  )
}

export default Hero
