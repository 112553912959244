import Strie from "../../../../components/Strie/Strie";
import IconNext from "../../../../media/icons/IconNext";
import "./AfricaSectionV2.css";
import "./StriesAdjustments.css";
// import Picture1 from "../../../../media/images/man_studying_2.jpg";
import Picture1 from "../../../../media/images/man_at_desk_1.jpg";
import Picture2 from "../../../../media/images/woman_studying_1.jpeg";
// import Picture3 from "../../../../media/images/co-studying-1.jpg";
// import Picture3 from "../../../../media/images/man-studying-4.jpeg";
import useIntersectionObserver from "../../../../Hooks/useIntersectionObserver";
import Picture3 from "../../../../media/images/man-studying-5.jpeg";

function KidsSection() {
  const [ref, isVisible] = useIntersectionObserver(0.3);

  return (
    // <div className="home-sections-wrapper">
    <div className="kids-section-wrapper">
      <div ref={ref} className={`kids-section-container`}>
        <div
          className={`kids-section-illustration ${isVisible ? "visible" : ""}`}
        >
          <img
            className="kids-section-image main-kids-image"
            src={Picture1}
            alt=" kids "
          ></img>
          <img className="kids-section-image" src={Picture2} alt="kids "></img>
          <img className="kids-section-image" src={Picture3} alt="kids "></img>
        </div>
        <div
          className={`kids-section-description ${isVisible ? "visible" : ""}`}
        >
          <div className="kids-section-header">
            {/* <h6>Title 2</h6> */}
            <h2 className="semi-bold">Formation Intensif </h2>
          </div>
          {/* <p>
            Pour vos <span className="bold">enfants</span> et{" "}
            <span className="bols">adolescents</span>, nous enseignons en
            utilisant les mêmes plateformes que les{" "}
            <span className="bold">professionnels</span>. Cela signifie que
            votre enfant apprendra aujourd’hui et deviendra un{" "}
            <span className="bold">expert</span> demain.
          </p> */}
          <span className="bols">
            4 jours pour Maîtrisez les Techniques de Création de Sites Web
            E-commerce :{" "}
          </span>
          <ul>
            <li>Design et Expérience Utilisateur </li>
            <li>Gestion des Produits </li>
            <li>Paiement et Sécurité</li>
            <li>Optimisation pour les Moteurs de Recherche (SEO) </li>
            <li>Analyse des Performances</li>
            <li>Outils Marketing et Promotionels</li>
          </ul>
          <div className="kids-section-footer">
            <a
              href="/construction-progress?title=Kids"
              className="kids-section-button"
            >
              {/* <span>NOS STAGES KIDS</span> */}
              <span>Plus d'information</span>
              <IconNext rotation="rotate-right" width={12} height={12} />
            </a>
            {/* <a
              href="/construction-progress?title=Ados"
              className="kids-section-button"
            >
              <span>NOS FORMATIONS ADOS</span>
              <span>LINK BUTTON 2.2</span>
              <IconNext rotation="rotate-right" width={12} height={12} />
            </a> */}
          </div>
        </div>
      </div>
      {/* <Strie
        scale={1.5}
        color="green-green"
        left="-290px"
        top="10px"
        id="kids-section-top-left-strie-1"
        zindex={-1}
      />
      <Strie
        scale={3}
        color="green-green"
        left="-260px"
        top="70px"
        id="kids-section-top-left-strie-2"
        zindex={-1}
      /> */}
      <Strie
        scale={12}
        color="red"
        left="100%"
        top="0%"
        zindex={-1}
        id="kids-section-top-right-strie-1"
      />
      <Strie
        scale={3}
        color="brown"
        left="110%"
        top="20%"
        zindex={-1}
        id="kids-section-bottom-right-strie-2"
      />
    </div>
  );
}

export default KidsSection;
