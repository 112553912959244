function IconNext({
  rotation = "",
  width = 24,
  height = 24,
  color = "currentColor",
}) {
  return (
    <div style={{ width: width, height: height }}>
      <svg
        className={rotation}
        height={height}
        viewBox="0 0 24 24"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m0 0h24v24h-24z" fill="none" />
        <path
          d="m9.31 6.71c-.39.39-.39 1.02 0 1.41l3.88 3.88-3.88 3.88c-.39.39-.39 1.02 0 1.41s1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41l-4.59-4.59c-.38-.38-1.02-.38-1.41.01z"
          fill={color}
        />
      </svg>
    </div>
  );
}

export default IconNext;
