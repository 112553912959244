import React from "react"
import "./courses.css"
import { coursesCard } from "../../dummydata"
import img from "../../../../media/images/c5.png"

const CoursesCard = () => {
  return (
    <>
      <section className='coursesCard-senegal'>
        <header className='header-senegal'>
          <h1>Formation populaire</h1>
        </header>
        <div className='container-senegal grid2'>
          {coursesCard.map((val) => (
            <div className='items-senegal' key={val.id}>
              <div className='content-senegal flex'>
                <div className='left'>
                  <div className='img-senegal'>
                    <img src={img} alt='' />
                  </div>
                </div>
                <div className='text-senegal'>
                  <h1>{val.coursesName}</h1>
                  <div className='rate-senegal'>
                    <i className='fa fa-star'></i>
                    <i className='fa fa-star'></i>
                    <i className='fa fa-star'></i>
                    <i className='fa fa-star'></i>
                    <i className='fa fa-star'></i>
                    <label htmlFor=''>(5.0)</label>
                  </div>
                </div>
              </div>

              <button className='button-senegal outline-btn'>Voir plus</button>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}

export default CoursesCard
