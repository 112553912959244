import "./AboutUsContextBox.css";
import useIntersectionObserver from "../../../../../Hooks/useIntersectionObserver";

export default function AboutUsContextBox({ children, title, animationDelay }) {
  const [ref, isIntersecting] = useIntersectionObserver();
  const style = {
    transitionDelay: `${animationDelay}`,
    transitionDuration: "3s",
  };
  const classNames = `about-us-context-box fade-in-from-right ${
    isIntersecting ? "visible" : ""
  }`;
  return (
    <>
      <div ref={ref} className={classNames} style={style}>
        <h2 className="about-us-context-box-title">{title}</h2>
        <div className="about-us-context-box-description">{children}</div>
      </div>
    </>
  );
}
