// import TestimonialCard from "./Components/TestimonialCard";
import TestimonialCardV2 from "../../../../components/TestimonialCardV2/TestimonialCardV2";
import "./TestimonialsSection.css";

export default function TestimonialsSection() {
  return (
    <>
      <div className="algeria-testimonials-wrapper">
        <div className="algeria-testimonials-container">
          <div className="algeria-testimonials-header">
            <div className="algeria-testimonials-header-badge">Témoignages</div>
            <h2 className="algeria-testimonials-title">
              Ce que nos clients disent
            </h2>
            <div className="algeria-testimonials-subtitle">
              {" "}
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Repellat suscipit, velit maxime quia debitis impedit voluptatum
                animi dolor distinctio, esse tenetur perspiciatis. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Repellat suscipit,
                velit maxime quia debitis impedit voluptatum animi dolor
                distinctio, rem quasi magnam quas consectetur quibusdam
                similique hic esse tenetur perspiciatis.
              </p>
            </div>
          </div>
          <div className="algeria-testimonials-body">
            <TestimonialCardV2
              id="1"
              personName={"John Doe"}
              companyName={"Company Name"}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat
              suscipit, velit maxime quia debitis impedit voluptatum animi dolor
              distinctio, rem quasi magnam quas consectetur quibusdam similique
              hic esse tenetur perspiciatis.
            </TestimonialCardV2>
            <TestimonialCardV2
              id="2"
              personName={"John Doe"}
              companyName={"Company Name"}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat
              suscipit, velit maxime quia debitis impedit voluptatum animi dolor
              distinctio, rem quasi magnam quas consectetur quibusdam similique
              hic esse tenetur perspiciatis.
            </TestimonialCardV2>
            <TestimonialCardV2
              id="3"
              personName={"John Doe"}
              companyName={"Company Name"}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat
              suscipit, velit maxime quia debitis impedit voluptatum animi dolor
              distinctio, rem quasi magnam quas consectetur quibusdam similique
              hic esse tenetur perspiciatis.
            </TestimonialCardV2>
          </div>
        </div>
      </div>
    </>
  );
}
