import useIntersectionObserver from "../../../../Hooks/useIntersectionObserver";
import Strie from "../../../../components/Strie/Strie";
import TrainingCardV2 from "../../../../components/TrainingCardV2/TrainingCardV2";
import { themeColors } from "../../../../utils/themeColors";
import "./Animations.css";
import "./StriesAdjustments.css";
import "./TrainingsSectionV5.css";

export default function TrainingsSection() {
  const [trainingsRef, isTrainingsVisible] = useIntersectionObserver(0.2);

  // Données des formations
  const data = [
    {
      id: 1,
      title: "Introduction à l'entrepreneuriat",
      Image: "entrepreneur-1.webp",
      durer: "5 jour",
      date: ["20/12/2024", "10/10/2024", "16/11/2024"],
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit...",
      modalData: {
        programme: [
          "Jour 1 : Introduction",
          "Jour 2 : Approfondissement",
          "Jour 3 : Examen final",
        ],
        prix: " 700",
        contactNumber: "+1234567890",
        downloadLink: "link-to-file-1.pdf",
      },
      itemColor: themeColors.haverstGold,
      btnHoverFillColor: themeColors.safetyOrange,
    },
    {
      id: 2,
      title: "Développement Web",
      Image: "hero_image_2.jpg",
      durer: "7 jour",
      date: ["20/12/2024", "10/10/2024", "16/11/2024"],
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit...",
      modalData: {
        programme: [
          "Jour 1 : HTML/CSS",
          "Jour 2 : JavaScript",
          "Jour 3 : Frameworks",
        ],
        prix: " 800",
        contactNumber: "+9876543210",
        downloadLink: "link-to-file-2.pdf",
      },
      itemColor: themeColors.safetyOrange,
      btnHoverFillColor: themeColors.haverstGold,
    },
    {
      id: 3,
      title: "Marketing Digital",
      Image: "startup-2.jpeg",
      durer: "10 jour",
      date: ["20/12/2024", "10/10/2024", "16/11/2024"],

      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit...",
      modalData: {
        programme: [
          "Jour 1 : HTML/CSS",
          "Jour 2 : JavaScript",
          "Jour 3 : Frameworks",
        ],
        prix: "1000",
        contactNumber: "+9876543210",
        downloadLink: "link-to-file-2.pdf",
      },
      itemColor: themeColors.safetyOrange,
      btnHoverFillColor: themeColors.haverstGold,
    },
    {
      id: 4,
      title: "Fondamentaux du business",
      Image: "startup-4.jpeg",
      durer: "12 jour",
      date: ["20/12/2024", "10/10/2024", "16/11/2024"],

      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit...",
      modalData: {
        programme: [
          "Jour 1 : HTML/CSS",
          "Jour 2 : JavaScript",
          "Jour 3 : Frameworks",
        ],
        prix: "1000",
        contactNumber: "+9876543210",
        downloadLink: "link-to-file-2.pdf",
      },
      itemColor: themeColors.safetyOrange,
      btnHoverFillColor: themeColors.haverstGold,
    },
    {
      id: 5,
      title: "Mentorat et Networking",
      Image: "projet2.webp",
      durer: "1 mois",
      date: ["20/12/2024", "10/10/2024", "16/11/2024"],

      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit...",
      modalData: {
        programme: [
          "Jour 1 : HTML/CSS",
          "Jour 2 : JavaScript",
          "Jour 3 : Frameworks",
        ],
        prix: "1300",
        contactNumber: "+9876543210",
        downloadLink: "link-to-file-2.pdf",
      },
      itemColor: themeColors.safetyOrange,
      btnHoverFillColor: themeColors.haverstGold,
    },
    {
      id: 6,
      title: "Pitching et Financement",
      Image: "pexels-photo-7679171.webp",
      durer: "2 mois",
      date: ["20/12/2024", "10/10/2024", "16/11/2024"],

      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit...",
      modalData: {
        programme: [
          "Jour 1 : HTML/CSS",
          "Jour 2 : JavaScript",
          "Jour 3 : Frameworks",
        ],
        prix: "1600",
        contactNumber: "+9876543210",
        downloadLink: "link-to-file-2.pdf",
      },
      itemColor: themeColors.safetyOrange,
      btnHoverFillColor: themeColors.haverstGold,
    },
  ];

  return (
    <>
      <section
        className="algeria-trainings-section--wrapper"
        id="algeria-trainings-section"
      >
        <div className="algeria-trainings-section--header">
          <div className="algeria-trainings-section--header-badge">
            Nos formations
          </div>
          <h2 className="algeria-trainings-section--header-title">
            Ce qu'on a à vous offrir
          </h2>
          <p className="algeria-trainings-section--header-description">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa
            repellendus blanditiis, quae necessitatibus saepe soluta voluptate
            beatae, commodi, esse libero dolores asperiores.
          </p>
        </div>
        <div
          ref={trainingsRef}
          className={`algeria-trainings-section--container ${
            isTrainingsVisible ? "visible" : ""
          }`}
        >
          {data.map((item) => (
            <TrainingCardV2
              key={item.id}
              id={item.id}
              title={item.title}
              Image={item.Image}
              durer={item.durer}
              prix={item.modalData.prix}
              date={item.date}
              itemColor={item.itemColor}
              btnHoverFillColor={item.btnHoverFillColor}
              ctaBtnText="En savoir plus"
              modalData={item.modalData} // On passe les données du modal ici
            >
              {item.description}
            </TrainingCardV2>
          ))}
        </div>
        <Strie
          scale={5.5}
          color="green-green"
          left="0%"
          top="10%"
          id="algeria-trainings-top-left-strie-1"
        />
        <Strie
          scale={3}
          color="green-green"
          left="0%"
          top="20%"
          id="algeria-trainings-top-left-strie-2"
        />
        <Strie
          scale={3}
          color="brown"
          left="100%"
          top="75%"
          id="algeria-trainings-bottom-right-strie-1"
        />
        <Strie
          scale={6}
          color="orange-africa"
          left="100%"
          top="90%"
          id="algeria-trainings-bottom-right-strie-2"
        />
      </section>
    </>
  );
}
