import Footer from "../../components/Footer/Footer";
import TrainingsSectionV5 from "../Algeria/sections/TrainingsSectionV5/TrainingsSectionV5";
import HeroSectionV2 from "../homepage/sections/hero-section/HeroSectionV2";

export default function NosFormation() {
  return (
    <>
      <section>
        <HeroSectionV2 />
      </section>
      <div className="tunisia-page-wrapper">
        <TrainingsSectionV5 />
    
      </div>
    </>
  );
}
