import { useTranslation } from "react-i18next";
import Strie from "../../../../components/Strie/Strie";
import useIntersectionObserver from "../../../../Hooks/useIntersectionObserver";
import BuildIcon from "../../../../media/icons/build-icon.png";
import CertificationIcon from "../../../../media/icons/certification-icon.png";
import FormateurIcon from "../../../../media/icons/formateur-icon.png";
import IntroSectionCard from "./components/IntroSectionCard";
import "./IntroSectionV2.css";
import "./StriesAdjustments.css";

function IntroSectionV2() {
  const [ref, isVisible] = useIntersectionObserver();
  const { t } = useTranslation();
  return (
    <div className="home-sections-wrapper">
      <div
        className={`intro-section-container transparent-container ${
          isVisible ? "visible" : ""
        }`}
      >
        <div className="intro-section-header">
          <h2>{t("introTitle")}</h2>
          {t("academy")}
          <h3 className="semi-bold intro-section-title">{t("trainings")}</h3>
        </div>
        <div className="intro-section-body" ref={ref}>
          <div
            className={`intro-section-card-animation-wrapper intro-section-card-delay-1 ${
              isVisible ? "visible" : ""
            }`}
          >
            <IntroSectionCard
              center="center"
              icon={CertificationIcon}
              icon_width="40px"
              title={t("digitalStrategy")}
            >
              {t("digitalStrategyDesc")}
            </IntroSectionCard>
          </div>

          <hr className="intro-section-body-separator" />
          <div
            className={`intro-section-card-animation-wrapper intro-section-card-delay-2 ${
              isVisible ? "visible" : ""
            }`}
          >
            <IntroSectionCard
              center="center"
              icon={FormateurIcon}
              icon_width="40px"
              title={t("webEcommerce")}
              description="Nos formateurs sont des experts diplômés et passionnés. Leur expérience et leur savoir-faire vous garantissent une formation de qualité, adaptée aux besoins du marché."
            >
              {t("webEcommerceDesc")}
              {/* Nos formateurs sont diplômés, fournissent des{" "}
              <span className="bold">supports</span> et dispensent une{" "}
              <span className="bold">pédagogie adaptée</span>. */}
            </IntroSectionCard>
          </div>
          <hr className="intro-section-body-separator" />
          <div
            className={`intro-section-card-animation-wrapper intro-section-card-delay-3 ${
              isVisible ? "visible" : ""
            }`}
          >
            <IntroSectionCard
              center="center"
              icon={BuildIcon}
              icon_width="40px"
              title={t("businessCoaching")}
              description="Bénéficiez de 20 heures de coaching avec un expert qui a déjà créé un ou plusieurs business en Afrique"
            >
              {t("businessCoachingDesc")}
            </IntroSectionCard>
          </div>
        </div>
      </div>
      <Strie
        scale={5}
        color="green-green"
        top="10%"
        left="-5%"
        zindex={2}
        id="intro-section-top-left-strie-1"
      />
      <Strie
        scale={2.5}
        color="green-green"
        top="40%"
        left="-5%"
        zindex={2}
        id="intro-section-top-left-strie-2"
      />
      <Strie
        scale={5}
        color="orange-africa"
        top="60%"
        left="100%"
        zindex={2}
        id="intro-section-bottom-right-strie-1"
      />
      <Strie
        scale={2.5}
        color="brown"
        top="40%"
        left="100%"
        zindex={2}
        id="intro-section-bottom-right-strie-2"
      />
    </div>
  );
}

export default IntroSectionV2;
