export default function AnimateOnScroll({
  children,
  animationClass,
  additionalClassNames = "",
  delay = "0s",
  duration = "1s",
  triggerAnimation = false,
}) {
  const classNames = `${additionalClassNames} ${
    triggerAnimation ? animationClass : "hidden"
  }`;

  console.log("classNames", classNames);

  return (
    <div
      className={classNames}
      style={{
        animationDelay: delay,
        animationDuration: duration,
        transitionDelay: delay,
        transitionDuration: duration,
      }}
    >
      {children}
    </div>
  );
}
