import "./AdulteSection.css";
import "./StriesAdjustments.css";
// import Image from "../../../../media/images/woman_studying_1.jpeg";
// import Image from "../../../../media/images/man-studying-1.jpg";
import Image from "../../../../media/images/happy-man-suit-1.webp";
// import Image from "../../../../media/images/old-man-working-1.jpeg";
// import Image from "../../../../media/images/old-man-working-2.jpeg";
// import Image from "../../../../media/images/old-man-working-3.jpeg";
import useIntersectionObserver from "../../../../Hooks/useIntersectionObserver";
import Strie from "../../../../components/Strie/Strie";
import IconNext from "../../../../media/icons/IconNext";

function AdulteSection() {
  const [ref, isVisible] = useIntersectionObserver(0.3);

  return (
    <div className="home-sections-wrapper">
      <div ref={ref} className={`adulte-section-container `}>
        <div
          className={`adulte-section-description ${isVisible ? "visible" : ""}`}
        >
          <div className="adulte-section header">
            {/* <h6>Title 1</h6> */}
            <h2 className="semi-bold">Formation Express</h2>
          </div>
          Que vous soyez{" "}
          {/* <span className="bold">
              salarié, entrepreneur, demandeur d’emploi
            </span>{" "}
            ou tout simplement <span className="bold">curieux</span>, vous
            pourrez développer, améliorer{" "}
            <span className="bold">vos compétences</span> en développement web,
            marketing digital et design. Cela vous permettra de progresser dans
            votre <span className="bold">carrière</span> ou d’en débuter une
            nouvelle. */}
          <span className="bold">
            3 jours pour Maîtrisez les Techniques de Gestion des Réseaux Sociaux
            :
          </span>{" "}
          Découvrez comment créer, planifier et exécuter des campagnes de
          marketing sur les réseaux sociaux pour engager votre audience et
          augmenter votre visibilité en ligne.
          <ul>
            <li>Optimisation vos Contenus pour Facebook, Instagram, TikTok </li>
            <li>Analyser vos Performances </li>
            <li>Faire de la pub sur les Réseaux Sociaux</li>
          </ul>
          <a href="./formations" className="adulte-section-button">
            {/* <span>NOS FORMATIONS</span> */}
            <span>Plus d'information</span>
            <IconNext rotation="rotate-right" width={12} height={12} />
          </a>
        </div>
        <div
          className={`adulte-section-illustration ${
            isVisible ? "visible" : ""
          }`}
        >
          <img
            className="adulte-section-image"
            src={Image}
            alt="adulte wyze"
          ></img>
        </div>
      </div>
      <Strie
        scale={6}
        color="green-green"
        left="-15%"
        top="45%"
        id="adulteV2-section-top-left-strie-1"
        zindex={-1}
      />
      <Strie
        scale={3}
        color="green-green"
        left="-10%"
        top="75%"
        id="adulteV2-section-top-left-strie-2"
        zindex={-1}
      />
    </div>
  );
}

export default AdulteSection;
