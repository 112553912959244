import Footer from "../../components/Footer/Footer";
import pageData from "../pageData.json";
import "./IvoryCoastPage.css";
import IvoryCoastHeroSection from "./sections/IvoryCoastHeroSection/IvoryCoastHeroSection";

import InfoTabsSectionV2 from "../Algeria/sections/InfoTabsSectionV2/InfoTabsSectionV2";
export default function IvoryCoastPage() {
  const { features, sources } = pageData.pageIvory;

  return (
    <>
      <IvoryCoastHeroSection />
      <InfoTabsSectionV2 features={features} sources={sources} />
  
    </>
  );
}
