import { useRef, useEffect, useState } from "react";
import { Parallax } from "react-parallax";
import "./HeroSectionV1.css";
import { Helmet } from "react-helmet";
import { Link } from "react-scroll";
import HeroBackground from "../../../../media/images/muslim_woman_studying_1.jpg";
import HeroBackgroundSmall from "../../../../media/images/muslim_woman_studying_1.jpg";

export default function HeroSectionV1() {
  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      console.log("resizing");
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const backgroundImage =
    windowWidth > 720 ? HeroBackground : HeroBackgroundSmall;
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  });

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          fetchpriority="high"
          as="image"
          href={backgroundImage}
          type="image/jpg"
        />
      </Helmet>
      <Parallax
        blur={0}
        bgImage={backgroundImage}
        bgImageStyle={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
        bgImageAlt="Hero Section"
        strength={400}
        className={`parallax-image-container ${
          isSectionIntersected ? "visible" : ""
        }`}
        style={{ transition: "all 2s ease-in-out" }}
      >
        <section ref={sectionRef} className="hero-section-1-wrapper">
          <div className="hero-section-1-container">
            <div className="hero-section-1-image-container"></div>
            <div
              className={`hero-section-1-body ${
                isSectionIntersected ? "visible" : ""
              }`}
            >
              <div className="hero-section-1-titles-container">
                <h1 className="hero-section-1-title">Wyze Africa</h1>
                <h1 className="hero-section-1-subtitle">Algérie</h1>
              </div>
              <h4 className="hero-section-1-slogan">Explore Create Innovate</h4>
              <a
                href="#algerian-trainings-section"
                className="hero-section-1-btn"
              >
                <span>Découvrir nos formations</span>
              </a>
              {/* <Link
                to="algerian-trainings-section"
                spy={true}
                smooth={true}
                offset={-70} // Adjust this offset as needed to fit your layout
                duration={500}
                className="hero-section-1-btn"
              >
                <span>Découvrir nos formations</span>
              </Link> */}
            </div>
          </div>
        </section>
      </Parallax>
    </>
  );
}
