import React from "react"
import Heading from "../common/heading/Heading"
import "./about.css"
import { homeAbout } from "../../dummydata"

import Strie from "../../../../components/Strie/Strie";

import img3 from "../../../../media/images/nature-1568850.jpg"


const AboutCard = () => {
  return (
    <>
      <section className='aboutHome-senegal'>
        <div className='container-senegal flexSB-senegal'>
          <div className='left-senegal row-senegal'>
            <img src={img3} alt='' />
          </div>
          <div className='right-senegal row-senegal'>
            <Heading subtitle='Lorem ippsum' title=' Lorem ipsum dolor sit amet consectetur adipisicing elit.' />
            <div className='items-senegal'>
              {homeAbout.map((val) => {
               
                return (
                  <div className='item-senegal flexSB-senegal'>
                    <div className='img-senegal'>
                      <img src={val.cover} alt='' />
                    </div>
                    <div className='text-senegal'>
                      <h2>{val.title}</h2>
                      <p>{val.desc}</p>
                    </div>
                  </div>
                )
              })}
            </div>

          </div>
          <Strie scale={4} color="red" top="230%" left="-4%" zIndex={-1} />
  <Strie scale={2} color="red" left="-1%" top="255%" zIndex={-1} />
  <Strie scale={4} color="blue" left="98%" top="210%" zIndex={-1} />
  {/* <Strie scale={2} color="blue" left="99%" top="237%" /> */}
        </div>
      </section>

    </>
  )
}

export default AboutCard
