import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaBars, FaTimes } from "react-icons/fa";
import { PiShoppingCartBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage"; // Pour accéder au panier

import Strie from "../../components/Strie/Strie.jsx";
import logo from "../../media/images/logo_wyze_africa_transparent_black.webp";
import LanguageSwitcher from "./LanguageSwitcher"; // Assure-toi d'importer le composant
import "./NavbarV2.css";
import ScrollToHashElement from "./ScrollToHashElement.jsx";
export default function NavbarV2() {
  const [isHidden, setIsHidden] = useState(false); // state for hidden the navbar
  const [isOpen, setIsOpen] = useState(false); // State for mobile menu toggle
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [cartCount, setCartCount] = useState(0); // État pour le nombre d'articles

  const handleScroll = () => {
    window.scrollY > 800 ? setIsHidden(true) : setIsHidden(false);
    // Hide navbar after scrolling past 800px
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);
  useEffect(() => {
    // Fonction pour récupérer les articles dans le localStorage
    const cartItems = secureLocalStorage.getItem("training-card")
      ? JSON.parse(secureLocalStorage.getItem("training-card"))
      : [];
    setCartCount(cartItems.length); // Mettre à jour le nombre d'articles
  }, []);

  const OpenMenu = useCallback(() => {
    setIsOpen(true);
  }, []);

  const CloseMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const showDropdown = useCallback(() => {
    setDropdownVisible(true);
  }, []);

  const hideDropdown = useCallback(() => {
    setDropdownVisible(false);
  }, []);

  const { t } = useTranslation();

  return (
    <nav className={isHidden ? "navbar-hidden" : "navbar"}>
      <ScrollToHashElement />
      <div className="logo-btn-box">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo Wyze" className="logoImageNavbar" />
          </Link>
        </div>
        <button className="nav-btn-menu-burger " onClick={OpenMenu}>
          <FaBars />
        </button>
      </div>

      <div className={isOpen ? "nav-menu active" : "nav-menu"}>
        <div className="nav-menu-strie-container">
          <Strie scale={5} color="brown-beige" left="-5%" top="20%" />
          <Strie scale={2.5} color="brown-beige" left="-3%" top="35%" />
          <Strie scale={5} color="light-harvest-gold" left="90%" top="75%" />
          <Strie scale={2.5} color="light-harvest-gold" left="95%" top="55%" />
        </div>
        <button className="btn-close-menu" onClick={CloseMenu}>
          <FaTimes />
        </button>
        <div className="nav-menu-li">
          <span
            className="nv-li dropdown"
            onMouseEnter={showDropdown}
            onMouseLeave={hideDropdown}
          >
            <span className="dropdown-btn"> {t("navbar.startup")}</span>
            <div style={{ height: "10px", width: "100%" }}></div>
            {dropdownVisible && (
              <div className="dropdown-content">
                <Link to="/formations/Algerie">{t("navbar.Algeria")}</Link>
                <Link to="/formations/Mali">{t("navbar.Mali")}</Link>
                <Link to="/formations/Senegal">{t("navbar.Senegal")}</Link>
                <Link to="/formations/cote-d-ivoire">
                  {t("navbar.Ivory Coast")}
                </Link>
                <Link to="/formations/Maroc">{t("navbar.Morocco")}</Link>
              </div>
            )}
          </span>{" "}
          <Link className="nv-li" to="/#formation-section" onClick={CloseMenu}>
            {t("navbar.Our trainings")}
          </Link>
          <Link className="nv-li" to="/#faq-section" onClick={CloseMenu}>
            {t("navbar.faq")}
          </Link>
          <Link className="nv-li" to="/#contact-section" onClick={CloseMenu}>
            {t("navbar.contact")}
          </Link>
          <div>
            <Link className="nv-li" to="/cartPage" onClick={CloseMenu}>
              <PiShoppingCartBold />
              <span className="number-of-items">({cartCount})</span>
            </Link>
          </div>
          <LanguageSwitcher />
        </div>
      </div>
    </nav>
  );
}
