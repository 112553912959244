import { useState } from "react";
import Slider from "react-slick";
import ButtonV6 from "../../../../../components/ButtonV6/ButtonV6";
import NextIconV2 from "../../../../../media/icons/NextIconV2";
import PreviousIconV2 from "../../../../../media/icons/PreviousIconV2";
import "./InfoTabsSlider.css";

function SlideTextContent({ children }) {
  return <>{children}</>;
}

const CustomPreviousArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      onClick={onClick}
      className={`carousel-3-btn carousel-3-next-btn ${className}`}
      type="button"
    >
      <PreviousIconV2 width={16} height={16} />
    </button>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      onClick={onClick}
      className={`carousel-3-btn carousel-3-next-btn ${className}`}
      type="button"
    >
      <NextIconV2 width={16} height={16} />
    </button>
  );
};

export default function InfoTabsSlider({ features, sources, onbuttonClick }) {
  const [activeSlide, setActiveSlide] = useState(0);
  if (!features || !Array.isArray(features)) {
    return <div>Aucune donnée disponible pour le slider.</div>;
  }
  const slides = features.map((feature, index) => ({
    id: feature.value,
    alt: feature.title,

    src: require(`../../../../../media/images/${feature.image}`),

    btnUrl: "/",
    content: (
      <SlideTextContent>
        <p>
          <strong>{feature.title}</strong> <br />
          {feature.description}
        </p>
      </SlideTextContent>
    ),
  }));

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slides",
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPreviousArrow />,
    beforeChange: (current, next) => setActiveSlide(next),
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slides.map((slide) => (
          <div className="wrapper" key={slide.id}>
            <img className="sliderImg" src={slide.src} alt={slide.alt} />
          </div>
        ))}
      </Slider>
      <div className="slider-content-container">
        <div className="slider-content">{slides[activeSlide]?.content}</div>
        <div className="slider-content-description-btn-container">
          <ButtonV6
            btnText="En savoir plus"
            onClick={onbuttonClick}
            linkUrl={null}
          />
        </div>
      </div>
    </div>
  );
}
