import React from "react"
import "./about.css"
import Back from "../common/back/Back"
import AboutCard from "./AboutCard"

const About = () => {
  return (
    <>

      {/* <Back title='formation' /> */}
      <AboutCard />
    </>
  )

}

export default About
