import "./Strie.css";

import PropTypes from "prop-types";

function Strie({
  className = "",
  color = "red",
  width = 100,
  height = 20,
  scale = 1,
  rotation = "-50deg",
  top = "0",
  left = "0",
  zindex = 1,
  id = "",
}) {
  return (
    <div
      className={`strie-container  ${color} ${className}`}
      style={{
        width: { width },
        height: { height },
        transform: `scale(${scale}) rotate(${rotation})`,
        top: `${top}`,
        left: `${left}`,
        zIndex: `${zindex}`,
      }}
      id={id}
    ></div>
  );
}

Strie.propTypes = {
  rotation: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  scale: PropTypes.number,
  color: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string,
  zindex: PropTypes.number,
};

export default Strie;
