import meter1 from "../../../media/images/tem.webp";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../../../media/images/arrow1.svg";
import arrow2 from "../../../media/images/arrow2.svg";
import colorSharp from "../../../media/images/color-sharp.png";

const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill-mali small-skill-section-mali" id="skills">
      <div className="container-mali">
        <div className="row-mali">
          <div className="col-12">
            <div className="skill-bx-mali wow-mali zoomIn-mali">
              <h2>Témoignage</h2>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br /> Lorem Ipsum has been the industry's standard dummy text.</p>
              <Carousel responsive={responsive} infinite={true} className="owl-carousel-mali owl-theme-mali skill-slider-mali">
                <div className="item-mali">
                  <img src={meter1} alt="Image-mali" />
                  <h6 >Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum excepturi non rem,</h6>
                </div>
                <div className="item-mali">
                  <img src={meter1} alt="Image-mali" />
                  <h6>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum excepturi non rem,</h6>
                </div>
                <div className="item-mali">
                  <img src={meter1} alt="Image-mali" />
                  <h6>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum excepturi non rem,</h6>
                </div>
                <div className="item-mali">
                  <img src={meter1} alt="Image-mali" />
                  <h6>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum excepturi non rem,</h6>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      {/* <img className="background-image-left" src={colorSharp} alt="Image" /> */}
    </section>
  );
}

export default Skills;
