import "./ContentSection1.css";
import StartupImage from "../../../../media/images/startup-4.jpeg";
import Strie from "../../../../components/Strie/Strie";
// import "./StriesAdjustments.css";
import useIntersectionObserver from "../../../../Hooks/useIntersectionObserver";

export default function SectionV2() {
  const [imageRef, isImageVisible] = useIntersectionObserver(0.2);
  const [contentRef, isContentVisible] = useIntersectionObserver(0.2);

  return (
    <section className="tunisia-section-1-wrapper">
      <div className="tunisia-section-1-container">
        <div
          ref={imageRef}
          className={`tunisia-section-1-illustration ${
            isImageVisible ? "visible" : ""
          }`}
        >
          <img
            src={StartupImage}
            alt="Hero"
            className="tunisia-section-1-image"
          />
        </div>
        <div
          ref={contentRef}
          className={`tunisia-section-1-content ${
            isContentVisible ? "visible" : ""
          }`}
        >
          <div className="tunisia-section-1-text">
            <h2 className="tunisia-section-1-title">
              Unlock Your Team's Potential
            </h2>
            <p className="tunisia-section-1-description">
              {/* Empower your team to focus on what matters most with our
              all-in-one platform. */}
              Lorem ipsum dolor sit amet consectetur adipisicing elit sed do
              eiusmod tempor.
            </p>
          </div>
          <div className="tunisia-section-1-features">
            <div
              className={`tunisia-section-feature-item ${
                isContentVisible ? "visible" : ""
              }`}
              id="tunisia-section-feature-item-1"
            >
              <div className="tunisia-section-feature-icon bg-muted">
                <RocketIcon className="icon" />
              </div>
              <div>
                <h6 className="tunisia-section-feature-title">
                  Accelerate Innovation
                </h6>
                <p className="tunisia-section-feature-description">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
            <div
              id="tunisia-section-feature-item-2"
              className={`tunisia-section-feature-item ${
                isContentVisible ? "visible" : ""
              }`}
            >
              <div className="tunisia-section-feature-icon bg-muted">
                <BriefcaseIcon className="icon" />
              </div>
              <div>
                <h6 className="tunisia-section-feature-title">
                  Boost Productivity
                </h6>
                <p className="tunisia-section-feature-description">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
            <div
              className={`tunisia-section-feature-item ${
                isContentVisible ? "visible" : ""
              }`}
              id="tunisia-section-feature-item-3"
            >
              <div className="tunisia-section-feature-icon bg-muted">
                <LightbulbIcon className="icon" />
              </div>
              <div>
                <h6 className="tunisia-section-feature-title">
                  Unlock your creativity
                </h6>
                <p className="tunisia-section-feature-description">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Strie
        scale={12}
        color="orange-africa"
        left="100%"
        top="0%"
        id="tunisia-section-1-top-right-strie-1"
      />
    </section>
  );
}

function BriefcaseIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16 20V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
      <rect width="20" height="14" x="2" y="6" rx="2" />
    </svg>
  );
}

function RocketIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z" />
      <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z" />
      <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0" />
      <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5" />
    </svg>
  );
}

function XIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  );
}

function LightbulbIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15 14c.2-1 .7-1.7 1.5-2.5 1-.9 1.5-2.2 1.5-3.5A6 6 0 0 0 6 8c0 1 .2 2.2 1.5 3.5.7.7 1.3 1.5 1.5 2.5" />
      <path d="M9 18h6" />
      <path d="M10 22h4" />
    </svg>
  );
}
