import Footer from "../../components/Footer/Footer";
import "./SenegalPage.css";
import About from "./sections/about/About";
import Courses from "./sections/allcourses/CourseHome";
import Home from "./sections/home/Home";
import Testimonal from "./sections/home/testimonal/Testimonal";
// import Section from "../Mali/sections/Section";
import pageData from "../pageData.json";
import FormationSection from "./sections/Formation/Formationv2";

import InfoTabsSectionV2 from "../Algeria/sections/InfoTabsSectionV2/InfoTabsSectionV2";
export default function SenegalPage() {
  const { features, sources } = pageData.pageSenegal;

  return (
    <>
      <div
        className="senegal-page"
        style={{ overflow: "hidden", position: "relative" }}
      >
        <Home />

        <div style={{ marginTop: "100vh" }}>
          <InfoTabsSectionV2 features={features} sources={sources} />
          <About />
        </div>
        <div style={{ marginTop: "50px" }}>
          <Courses />
        </div>
        {/* <Team/> */}

        <div style={{ marginTop: "50px" }}>
          <FormationSection />
        </div>

        <Testimonal />
        <div
          style={{
            marginTop: "30vh",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <Section /> */}
        </div>


      </div>
    </>
  );
}
