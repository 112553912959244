import React, { useState, useEffect } from "react";
import "./AvisSection2.css";
// import AvisCard from "../../../../components/AvisCard/AvisCard";
import AvisCard from "./components/AvisCard";
// import Picture1 from "../../../../media/images/woman-picture-1.png";
// import Picture2 from "../../../../media/images/man-picture.png";
import Picture1 from "../../../../media/icons/afro-woman-character.jpg";
import Picture2 from "../../../../media/icons/afro-man-character.jpg";
import Picture3 from "../../../../media/icons/afro-girl-character.jpg";

function AvisSection() {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setCursorPosition({
        x: event.clientX,
        y: event.clientY,
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="avis-section-wrapper">
      <div className="avis-section-container">
        <div className="avis-section-header">
          <h6 className="avis-section-subtitle">Avis</h6>
          <h2 className="semi-bold avis-section-title">
            Ce que disent nos clients
          </h2>
        </div>
        <div className="avis-section-details">
          <AvisCard
            picture={Picture1}
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua dolore magna."
            }
            name={"Sarah"}
            stars={4.2}
            area={1}
          />
          <AvisCard
            picture={Picture2}
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor."
            }
            name={"Toib"}
            stars={5}
            area={2}
          />
          <AvisCard
            picture={Picture3}
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
            name={"Khadija"}
            stars={4}
            area={3}
          />
          {/* <AvisCard
            picture={Picture3}
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
            name={"Khadija"}
            stars={4}
            area={4}
          />
          <AvisCard
            picture={Picture3}
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
            name={"Khadija"}
            stars={4}
            area={5}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default AvisSection;
