import { PayPalScriptProvider } from "@paypal/react-paypal-js"; // Import du fournisseur PayPal

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./css/colors.css";
import "./index.css";
import "./locales/i18n";
import reportWebVitals from "./reportWebVitals";
// import Navbar from "./components/Navbar/Navbar";
import NavbarV2 from "./components/NavbarV2/NavbarV2";
// import NavbarV3 from "./components/NavbarV3/NavbarV3";
import BackToTopButton from "./components/CookieConsent/BackToTop";
import CookiesConsent from "./components/CookieConsent/CookiesConsent";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import AppContext from "./context";
import Footer from "./components/Footer/Footer";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
  <PayPalScriptProvider
    options={{
      "client-id":
        "AfxP9pqxico9r2R0wtOaTOm5Vy8jqX-5q-71g45kcmUZMsacVId_I2Siv9zAXSDzkz4IqncpAV5hzLRp",
    }}
  >
    <BrowserRouter>
      {/* <Navbar /> */}
      <AppContext>
        <NavbarV2 />
        {/* <NavbarV3 /> */}
        <ScrollToTop />
        <App />
        <CookiesConsent />
        <BackToTopButton />
        <Footer/>
      </AppContext>
    </BrowserRouter>
  </PayPalScriptProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
