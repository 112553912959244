import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import DOMPurify from "dompurify";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import PhoneInput from "react-phone-number-input";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Select from "react-select";
import countryList from "react-select-country-list";
import payPalImage from "./validation-image/paypal_logo.png";

import "./Validation.css";

function Validation() {
  const formRef = useRef(null);
  const initialOptions = {
    "client-id":
      "AVNvzinE4-YJiNRwQpTadFajzsagGsboK6SoUWjmnzk0NbKUHoH298ayq1fmYXEe5LlhVdiVld4hzamt",
    currency: "EUR",
    intent: "capture",
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    trigger,
    reset
  } = useForm({ mode: "onChange" });
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [tva, setTva] = useState(0);
  const [total, setTotal] = useState(0);
  const [dates, setDates] = useState({});
  const TVA_RATE = 0.2; // 20% TVA

  useEffect(() => {
    const storedCart = secureLocalStorage.getItem("training-card");
    const storedTotal = secureLocalStorage.getItem("training-card_total");
    if (storedCart && storedTotal) {
      const parsedCart = JSON.parse(storedCart);
      setCart(parsedCart);

      const retrievedDates = {};
      parsedCart.forEach((item) => {
        const date = secureLocalStorage.getItem(`date_${item.id}`);
        if (date) {
          retrievedDates[item.id] = date;
        }
      });
      setDates(retrievedDates);

      const calculatedSubTotal = parsedCart.reduce(
        (acc, item) => acc + parseFloat(item.prix),
        0
      );
      setSubTotal(calculatedSubTotal);

      const calculatedTva = calculatedSubTotal * TVA_RATE;
      setTva(calculatedTva);

      const calculatedTotal = calculatedSubTotal + calculatedTva;
      setTotal(calculatedTotal);
    }
  }, []);
  const countryOptions = countryList().getData();
  const [isFocused, setIsFocused] = useState(false);
  const [isPayPalDisabled, setIsPayPalDisabled] = useState(true);
  const [formData, setFormData] = useState({});
  const [isValid, setIsValid] = useState(false);

  const handleFormSubmit = async (data) => {
    const sanitizedData = {
      prenom: DOMPurify.sanitize(data.prenom),
      nom: DOMPurify.sanitize(data.nom),
      pays: data.pays ? DOMPurify.sanitize(data.pays.label) : "",
      rue: DOMPurify.sanitize(data.rue),
      postal: DOMPurify.sanitize(data.postal),
      ville: DOMPurify.sanitize(data.ville),
      tel: DOMPurify.sanitize(data.tel),
      email: DOMPurify.sanitize(data.email),
    };
    setFormData(sanitizedData);
    const valid = await trigger();
    setIsValid(valid);
  };

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then(async (details) => {
      const name = details.payer.name.given_name;
      alert(`Transaction completed by ${name}`);

      // Combine formData and payment details
      const combinedData = {
        ...formData,
        paymentDetails: details,
      };
      // Process combined data as needed
      try {
        const response = await fetch('http://your-backend-api/endpoint', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(combinedData)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        console.log('Data successfully registered:', responseData);
        reset();
        setIsPayPalDisabled(true);
    } catch (error) {
        console.error('Error registering data:', error);
    }
    });
  };

  useEffect(() => {
    const subscription = watch(async () => {
      const isValidForm = await trigger();
      setIsPayPalDisabled(!isValidForm);
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);
  const onCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: total.toFixed(2), // Utilisation du `total` calculé
            currency_code: "EUR", // Assure-toi que la devise est correcte
          },
        },
      ],
    });
  };

  const handlePayPalClick = async (data, actions) => {
    const isValid = await trigger(); // Validation du formulaire
    if (!isValid) {
      setIsPayPalDisabled(true);
      console.log("Formulaire invalide");
      return actions.reject(); // Rejet si formulaire invalide
    } else {
      setIsPayPalDisabled(false);
      await handleSubmit(handleFormSubmit)(); // Soumission du formulaire
      return onCreateOrder(data, actions); // Création de la commande
    }
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div className="validation">
        <div className="validation-container">
          <div className="validation-wrapper">
            <div className="validation-grid">
              <div className="panier">
                <h2>Détails de Facturation</h2>
                <form onSubmit={handleSubmit(handleFormSubmit)} ref={formRef}>
                  <div className="input-layout">
                    <div className="input-container">
                      <label className="label" htmlFor="prenom">
                        Prénom<span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        id="prenom"
                        {...register("prenom", {
                          required: "Prénom est requis",
                        })}
                        placeholder="Joe"
                      />
                      {errors.prenom && (
                        <span style={{ color: "red" }}>
                          {errors.prenom.message}
                        </span>
                      )}
                    </div>
                    <div className="input-container">
                      <label className="label" htmlFor="nom">
                        Nom<span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        id="nom"
                        {...register("nom", { required: "Nom est requis" })}
                        placeholder="Shmoe"
                      />
                      {errors.nom && (
                        <span style={{ color: "red" }}>
                          {errors.nom.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="input-container">
                    <label className="label" htmlFor="pays">
                      Pays/region<span className="star">*</span>
                    </label>
                    <Controller
                      name="pays"
                      control={control}
                      rules={{ required: "Pays/region est requis" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={countryOptions}
                          placeholder="Sélectionnez votre pays"
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              height: "47px",
                              borderColor: baseStyles.borderColor,
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "6px",
                            colors: {
                              ...theme.colors,
                              primary25: "hsl(0, 0%, 80%)",
                              primary: "#00213B",
                            },
                          })}
                          onChange={(value) => {
                            if (value) {
                              clearErrors("pays");
                              field.onChange(value);
                            } else {
                              setError("pays", {
                                type: "required",
                                message: "Pays/region est requis",
                              });
                              field.onChange(null); // Set to null if no value selected
                            }
                          }}
                          value={field.value || null}
                        />
                      )}
                    />
                    {errors.pays && (
                      <span style={{ color: "red" }}>
                        {errors.pays.message}
                      </span>
                    )}
                  </div>
                  <div className="input-container">
                    <label className="label" htmlFor="rue">
                      Numero et nom de rue<span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      id="pays"
                      {...register("rue", {
                        required: "Numéro et nom de rue est requis",
                      })}
                      placeholder="11 bd baille"
                    />
                    {errors.rue && (
                      <span style={{ color: "red" }}>{errors.rue.message}</span>
                    )}
                  </div>

                  <div className="input-container">
                    <label className="label" htmlFor="postal">
                      Code postal<span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      id="pays"
                      {...register("postal", {
                        required: "Code postal est requis",
                      })}
                      placeholder="20023"
                    />
                    {errors.postal && (
                      <span style={{ color: "red" }}>
                        {errors.postal.message}
                      </span>
                    )}
                  </div>
                  <div className="input-container">
                    <label className="label" htmlFor="ville">
                      Ville<span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      id="ville"
                      {...register("ville", { required: "Ville est requise" })}
                      placeholder="Paris"
                    />
                    {errors.ville && (
                      <span style={{ color: "red" }}>
                        {errors.ville.message}
                      </span>
                    )}
                  </div>
                  <div className="input-container">
                    <label className="label" htmlFor="tel">
                      Téléphone<span className="star">*</span>
                    </label>
                    <Controller
                      name="tel"
                      control={control}
                      rules={{
                        required: "Téléphone est requis",
                        validate: (value) =>
                          (value && value.length >= 10) ||
                          "Le numéro de téléphone est incomplet",
                      }}
                      render={({ field }) => (
                        <PhoneInput
                          {...field}
                          defaultCountry="FR"
                          placeholder="07 52 36 95"
                          onFocus={() => setIsFocused(true)}
                          onBlur={() => setIsFocused(false)}
                        />
                      )}
                    />
                    {errors.tel && (
                      <span style={{ color: "red" }}>{errors.tel.message}</span>
                    )}
                  </div>
                  <div className="input-container">
                    <label className="label" htmlFor="email">
                      E-mail<span className="star">*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      {...register("email", { required: "E-mail est requis" })}
                      placeholder="joeshmoe@yahoo.com"
                    />
                    {errors.email && (
                      <span style={{ color: "red" }}>
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                </form>
              </div>

              <section className="totale">
                <h2>Validation Commande</h2>
                <div className="total">
                  <h3>Formation</h3>
                  <h3>Sous-Total</h3>
                </div>
                <div>
                  <div className="divider"></div>
                  {cart.map((item) => (
                    <div key={item.id} className="order-item">
                      <div>
                        {" "}
                        <p>Formation : {item.title}</p>
                        <p>
                          Date choisie :{" "}
                          {dates[item.id] || "Aucune date sélectionnée"}
                        </p>
                      </div>
                      <div>
                        <span> {item.prix}€</span>
                      </div>
                    </div>
                  ))}
                </div>

                <div className=" total-summary">
                  <div className="total">
                    <h3>Sous-total</h3>
                    <span className="sub-total-amount">
                      {subTotal.toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </span>
                  </div>
                  <div className="divider"></div>
                  <div className="total">
                    <h3>TVA (20%)</h3>
                    <span className="tva-amount">
                      {tva.toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </span>
                  </div>

                  <div className="divider"></div>
                  <div className="total">
                    <h3>Total</h3>
                    <span className="total-amount">
                      {total.toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </span>
                  </div>
                  <div className="divider"></div>
                </div>
                <div className="flex-colum">
                  {!isPayPalDisabled ? (
                    <PayPalButtons
                      style={{
                        layout: "vertical",
                        height: 50,
                        borderRadius: 10,
                      }}
                      createOrder={handlePayPalClick}
                      onApprove={(data, actions) =>
                        onApproveOrder(data, actions)
                      }
                      onError={(err) => {
                        console.error("PayPal Buttons Error:", err);
                      }}
                    />
                  ) : (
                    <button
                      className="btn"
                      type="button"
                      disabled={true}
                      style={{
                        height: 50,
                        borderRadius: 10,
                        backgroundColor: "#e0e0e0", // Couleur de fond grise pour l'apparence désactivée
                        cursor: "not-allowed",
                      }}
                    >
                      <img
                        className="paypal"
                        src={payPalImage}
                        alt="paypal image"
                      />
                    </button>
                  )}
                </div>
                <div className="push-to-bottom">
                  <Link to={"/cartPage"} className="link">
                    <IoArrowBackCircleOutline
                      size={30}
                      className="icon"
                      color="#97179c"
                    />
                    Retour au panier
                  </Link>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </PayPalScriptProvider>
  );
}

export default Validation;
