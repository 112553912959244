import React, { useState } from "react";
import ButtonV6 from "../../../../components/ButtonV6/ButtonV6";
import Modal from "../../../../components/Modal/Modal"; // Importation du composant Modal
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../../components/Tabs/Tabs";
import TabTitleListBtn from "../../../../components/Tabs/TabTitleListBtn";
import useIntersectionObserver from "../../../../Hooks/useIntersectionObserver";
import InfoTabsSlider from "./Components/InfoTabsSlider";
import "./InfoTabsSectionV2.css";

export default function InfoTabsSectionV2({ features, sources }) {
  const [imageRef, isVisible] = useIntersectionObserver(0.3);
  const [descriptionRef, isDescriptionVisible] = useIntersectionObserver(0.3);
  const [headerRef, isHeaderVisible] = useIntersectionObserver(0.3);
  const [showModal, setShowModal] = useState(false); // État pour le modal
  const [activeTab, setActiveTab] = useState("1");

  const toggleModal = () => {
    console.log("Toggling modal. Current state:", showModal); // Ajoute ceci pour vérifier l'état
    setShowModal(!showModal);
    console.log("Modal state after toggle:", !showModal); // Ajoute ceci pour vérifier l'état après mise à jour
  };

  const handleTabChange = (value) => {
    setActiveTab(value);
    setShowModal(false); // Fermer le modal lors du changement de tab
  };

  return (
    <>
      <section className="algeria-info-tabs-section-wrapper">
        <div className="algeria-info-tabs-section-container">
          <div
            ref={headerRef}
            className={`algeria-info-tabs-section-header ${
              isHeaderVisible ? "visible" : ""
            }`}
          >
            <h2 className="algeria-info-tabs-section-header-title">
              {features[0].title1}
            </h2>
            <p className="algeria-info-tabs-section-header-description">
              {features[0].paragraphe}
            </p>
          </div>

          <div className="algeria-info-tabs-section-tabs-wrapper">
            <Tabs
              defaultValue="1"
              additionalClassNames="algeria-info-tabs-section-tabs"
              onValueChange={handleTabChange}
            >
              <TabsList>
                {features.map((feature, idx) => (
                  <TabsTrigger
                    key={feature.value}
                    value={feature.value}
                    index={idx}
                    defaultValue={"1"}
                  >
                    <TabTitleListBtn btnText={feature.title} />
                  </TabsTrigger>
                ))}
              </TabsList>
              {features.map((feature) => (
                <TabsContent key={feature.value} value={feature.value}>
                  <div className="tab-content-inner">
                    <img
                      ref={imageRef}
                      src={require(`../../../../media/images/${feature.image}`)}
                      alt={feature.title}
                      className={`tab-content-image info-tabs-section-image ${
                        isVisible ? "visible" : ""
                      }`}
                    />

                    <div
                      ref={descriptionRef}
                      className={`tab-content-description ${
                        isDescriptionVisible ? "visible" : ""
                      }`}
                    >
                      <h3 className="tab-content-title">{feature.title}</h3>
                      <p>{feature.description}</p>
                      {feature.value === "3" && (
                        <div>
                          <ol>
                            {feature.steps.map((step, index) => (
                              <li key={index}>{step}</li>
                            ))}
                          </ol>
                        </div>
                      )}

                      <div className="tab-content-description-btn-container">
                        <ButtonV6
                          btnText="En savoir plus"
                          onClick={toggleModal} // Appelle toggleModal lors du clic
                          linkUrl={null} // Pas de redirection
                        />
                      </div>
                    </div>
                  </div>
                </TabsContent>
              ))}
            </Tabs>
          </div>
        </div>
        <div className="algeria-carousel-info-tabs">
          <InfoTabsSlider features={features} onbuttonClick={toggleModal} />
        </div>
      </section>

      <Modal show={showModal} onClose={toggleModal}>
        <h3>Sources</h3>
        <ul>
          {sources.map((source, index) => (
            <li key={index}>
              <a
                href={source}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underlin", color: "blue" }}
              >
                {source}
              </a>
            </li>
          ))}
        </ul>
        <div className="modal-button-container">
          <ButtonV6 btnText="Fermer" onClick={toggleModal} />
        </div>
      </Modal>
    </>
  );
}
