import { Link } from "react-router-dom";
import "./ButtonV6.css";

export default function ButtonV6({
  btnText,
  linkUrl,
  hoverFillColor,
  className: additionalClassnames = "",
  onClick, // Ajout de la prop onClick
}) {
  const classNames = `cta ${additionalClassnames}`;

  return (
    <>
      <Link
        to={linkUrl || "#"} // Si linkUrl est fourni, sinon rester sur la même page
        className={classNames}
        style={{ "--hover-fill-color": hoverFillColor }}
        onClick={(e) => {
          if (onClick) {
            e.preventDefault(); // Empêche le comportement par défaut si un onClick est défini
            console.log("ButtonV6 clicked");
            onClick(); // Appelle la fonction onClick
          }
        }}
      >
        <span>{btnText}</span>
        <svg width="13px" height="10px" viewBox="0 0 13 10">
          <path d="M1,5 L11,5"></path>
          <polyline points="8 1 12 5 8 9"></polyline>
        </svg>
      </Link>
    </>
  );
}
