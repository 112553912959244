/* eslint-disable react/style-prop-object */
import "./FeaturesSectionV1.css";
export default function FeaturesSectionV1() {
  return (
    <>
      <section className="algeria-features-section-wrapper">
        <div className="algeria-features-section-container">
          <h2 className="algeria-features-section-title">
            Empowering African Entrepreneurs
          </h2>
          <p className="algeria-features-section-subtitle-description">
            At Wyze Africa, we believe in the boundless potential of African
            entrepreneurs. Our comprehensive courses and trainings are designed
            to equip you with the skills, knowledge, and resources to thrive in
            the global marketplace.
          </p>

          <div className="algeria-features-section-feature-cards-container">
            <Feature title="Tailored for African Entrepreneurs">
              Our programs address the unique challenges and opportunities faced
              by entrepreneurs in Africa, providing practical solutions and
              strategies for success.
            </Feature>
            <Feature title="Experienced Instructors">
              Learn from a diverse team of seasoned entrepreneurs, business
              leaders, and industry experts who have navigated the African
              entrepreneurial landscape.
            </Feature>
            <Feature title="Peer-to-Peer Networking">
              Connect with a vibrant community of like-minded entrepreneurs,
              share experiences, and build valuable partnerships.
            </Feature>
            <Feature title="Practical, Hands-on Approach">
              Our courses and trainings prioritize practical application,
              empowering you to immediately implement what you've learned.
            </Feature>
            <Feature title="Ongoing Support and Mentorship">
              Benefit from personalized guidance and support from our team, even
              after completing your program, to ensure your long-term success.
            </Feature>
            <Feature title="Accessible and Affordable">
              Our courses and trainings are designed to be accessible and
              affordable, removing barriers to education and empowering
              entrepreneurs across Africa.
            </Feature>
          </div>
        </div>
      </section>
    </>
  );
}

function Feature({ title, children }) {
  return (
    <>
      <div className="algeria-program-feature-container">
        <h6 className="algeria-program-feature-item-title">{title}</h6>
        <p className="algeria-program-feature-item-description">{children}</p>
      </div>
    </>
  );
}
