import React from "react";
import "./MentorSection.css";
import mentorImage from "../../../media/images/co-studying-1.jpg"; // Assurez-vous de donner le bon chemin de l'image

const mentors = [
  {
    name: "John Doe",
    title: "Experienced Entrepreneur and Investor",
    image: mentorImage,
  },
  {
    name: "Jane Smith",
    title: "Digital Marketing Expert",
    image: mentorImage,
  },
  {
    name: "Ali Baba",
    title: "Web Development Specialist",
    image: mentorImage,
  },
  {
    name: "Fatima Zahra",
    title: "Startup Funding Specialist",
    image: mentorImage,
  },
];

export default function MentorSection() {
  return (
    <div className="mentor-section-mali">
      <h2 className="mentor-section-title-mali">Meet Our Mentors</h2>
      <div className="mentor-cards-mali">
        {mentors.map((mentor, index) => (
          <div key={index} className="mentor-card-mali">
            <img src={mentor.image} alt={mentor.name} className="mentor-image-mali" />
            <h3 className="mentor-name-mali">{mentor.name}</h3>
            <p className="mentor-title-mali">{mentor.title}</p>
            <button className="mentor-connect-button-mali">Connect</button>
          </div>
        ))}
      </div>
    </div>
  );
}
