import React, { useState } from "react";
import secureLocalStorage from "react-secure-storage";

import "./CartElements.css"; // Assurez-vous d'importer le fichier CSS pour les styles
function CartElement({ element, deleteItem }) {
  const [selectedDate, setSelectedDate] = useState("");
  // Forcer la date à être un tableau si elle ne l'est pas déjà
  const dates =
    Array.isArray(element.date) && element.date.length > 0 ? element.date : []; //  d'importation d'image locale
  let imageUrl;
  try {
    imageUrl = require(`../../media/images/${element.Image}`);
  } catch (error) {
    console.error(error);
    imageUrl = null;
  } // Fonction pour gérer la sélection de la date
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    // Mettre à jour la date sélectionnée dans le localStorage pour cet item
    secureLocalStorage.setItem(`date_${element.id}`, event.target.value);
  };

  return (
    <div className="cart-element-image">
      <div className="cart-img">
         <img
        src={imageUrl}
        alt={element.title}
        /*className={`tab-content-image info-tabs-section-image ${
          isVisible ? "visible" : ""
        }`}*/
      />
      </div>
     
      <div className="cart-element-details">
        <h4 className="cart-element-title">{element.title}</h4>
        <p className="cart-element-price">Prix:{element.prix}€</p>
        <p className="cart-element-duration">Durée : {element.durer}</p>

        {/* Sélection de la date */}
        {/* Affichage de la sélection des dates */}
        {dates.length > 0 ? (
          <div className="cart-element-date">
            <label htmlFor="date-select">Choisissez une date :</label>
            <select
              id="date-select"
              value={selectedDate}
              onChange={handleDateChange}
            >
              <option value="">Sélectionner une date</option>
              {dates.map((date, index) => (
                <option key={index} value={date}>
                  {date}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <p>Aucune date disponible</p>
        )}

        <div className="cart-element-actions">
          <button
            onClick={() => deleteItem(element.id)}
            className="remove-button"
          >
            Retirer du panier
          </button>
        </div>
      </div>
    </div>
  );
}

export default CartElement;
