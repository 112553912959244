import "./InfoTabsSection.css";
import tabImage from "../../../../media/images/happy-man-1.webp";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "../../../../components/Tabs/Tabs";
import TabTitleListBtn from "../../../../components/Tabs/TabTitleListBtn";
import ButtonV6 from "../../../../components/ButtonV6/ButtonV6";
import InfoTabsSlider from "./Components/InfoTabsSlider";
import useIntersectionObserver from "../../../../Hooks/useIntersectionObserver";

const features = [
  {
    value: "1",
    title: "Feature 1",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisiipsa repellendus blanditis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa repellendus blanditiis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisiipsa repellendus blanditis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa repellendus blanditiis, quae necessitatibus saepe soluta voluptate beat.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisiipsa repellendus blanditis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa repellendus blanditiis, quae necessitatibus saepe soluta voluptate beatae.",
    image: tabImage,
  },
  {
    value: "2",
    title: "Feature 2",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisiipsa repellendus blanditis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa repellendus blanditiis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisiipsa repellendus blanditis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa repellendus blanditiis, quae necessitatibus saepe soluta voluptate beat.",
    image: tabImage,
  },
  {
    value: "3",
    title: "Feature 3",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisiipsa repellendus blanditis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa repellendus blanditiis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisiipsa repellendus blanditis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa repellendus blanditiis, quae necessitatibus saepe soluta voluptate beat.",
    image: tabImage,
  },
  {
    value: "4",
    title: "Feature 4",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisiipsa repellendus blanditis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa repellendus blanditiis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisiipsa repellendus blanditis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa repellendus blanditiis, quae necessitatibus saepe soluta voluptate beat.",
    image: tabImage,
  },
  {
    value: "5",
    title: "Feature 5",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisiipsa repellendus blanditis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa repellendus blanditiis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisiipsa repellendus blanditis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa repellendus blanditiis, quae necessitatibus saepe soluta voluptate beat.",
    image: tabImage,
  },
  {
    value: "6",
    title: "Feature 6",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisiipsa repellendus blanditis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa repellendus blanditiis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisiipsa repellendus blanditis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa repellendus blanditiis, quae necessitatibus saepe soluta voluptate beat.",
    image: tabImage,
  },
  {
    value: "7",
    title: "Feature 7",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisiipsa repellendus blanditis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa repellendus blanditiis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisiipsa repellendus blanditis, quae necessitatibus saepe soluta voluptate beatae, commodi, esse libero dolores asperiores. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa repellendus blanditiis, quae necessitatibus saepe soluta voluptate beat.",
    image: tabImage,
  },
];

const carouselImages = [
  "https://d33wubrfki0l68.cloudfront.net/dd23708ebc4053551bb33e18b7174e73b6e1710b/dea24/static/images/wallpapers/shared-colors@2x.png",
  "https://d33wubrfki0l68.cloudfront.net/49de349d12db851952c5556f3c637ca772745316/cfc56/static/images/wallpapers/bridge-02@2x.png",
  "https://d33wubrfki0l68.cloudfront.net/594de66469079c21fc54c14db0591305a1198dd6/3f4b1/static/images/wallpapers/bridge-01@2x.png",
];

export default function InfoTabsSection() {
  const [imageRef, isVisible] = useIntersectionObserver(0.3);
  const [descriptionRef, isDescriptionVisible] = useIntersectionObserver(0.3);
  const [headerRef, isHeaderVisible] = useIntersectionObserver(0.3);

  return (
    <>
      <section className="tunisia-info-tabs-section-wrapper">
        <div className="tunisia-info-tabs-section-container">
          <div
            ref={headerRef}
            className={`tunisia-info-tabs-section-header ${
              isHeaderVisible ? "visible" : ""
            }`}
          >
            <h2 className="tunisia-info-tabs-section-header-title">
              Quae necessitatibus saepe soluta voluptate beatae commodi esse
              libero
            </h2>
            <div className="tunisia-info-tabs-section-header-description">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi
              ipsa repellendus blanditis, quae necessitatibus saepe soluta
              voluptate beatae, commodi, esse libero dolores asperiores. Lorem
              ipsum dolor sit amet, consectetur adipisicing elit. Nisi ipsa
              repellendus blanditiis, quae necessitatibus saepe soluta voluptate
              beatae, commodi, esse libero dolores asperiores.
            </div>
          </div>
          <div className="tunisia-info-tabs-section-tabs-wrapper">
            <Tabs
              defaultValue="1"
              additionalClassNames="tunisia-info-tabs-section-tabs"
            >
              <TabsList>
                {features.map((feature, idx) => (
                  <TabsTrigger
                    key={feature.value}
                    value={feature.value}
                    index={idx}
                    defaultValue={"1"}
                  >
                    {/* {feature.title} */}
                    <TabTitleListBtn btnText={feature.title} />
                  </TabsTrigger>
                ))}
              </TabsList>
              {features.map((feature) => (
                <TabsContent key={feature.value} value={feature.value}>
                  <div className="tab-content-inner">
                    <img
                      ref={imageRef}
                      src={feature.image}
                      alt={feature.title}
                      // className="tab-content-image info-tabs-section-image"
                      className={`tab-content-image info-tabs-section-image ${
                        isVisible ? "visible" : ""
                      }`}
                    />
                    <div
                      ref={descriptionRef}
                      className={`tab-content-description ${
                        isDescriptionVisible ? "visible" : ""
                      }`}
                    >
                      <h3 className="tab-content-title">{feature.title}</h3>
                      <p>{feature.description}</p>
                      <div className="tab-content-description-btn-container">
                        <ButtonV6 btnText="En savoir plus" />
                        {/* <button className="learn-more-button">Learn More</button> */}
                      </div>
                    </div>
                  </div>
                </TabsContent>
              ))}
            </Tabs>
          </div>
        </div>
        <div className="tunisia-carousel-info-tabs">
          <InfoTabsSlider />
        </div>
      </section>
    </>
  );
}
