export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 2,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 3,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];

export const coursesCard = [
  {
    id: 1,
    cover: "../images/courses/c1.png",
    coursesName: "Lorem ipsum",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "Lorem ipsum",
        totalTime: "Lorem ipsum",
      },
    ],
    priceAll: "Lorem ipsum",
    pricePer: "Lorem ipsum",
  },
  {
    id: 2,
    cover: "../images/courses/c2.png",
    coursesName: "Lorem ipsum",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "Lorem ipsum",
        totalTime: "Lorem ipsum",
      },
    ],
    priceAll: "Lorem ipsum",
    pricePer: "Lorem ipsum",
  },
  {
    id: 3,
    cover: "../images/courses/c3.png",
    coursesName: "Lorem ipsum",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "Lorem ipsum",
        totalTime: "Lorem ipsum",
      },
    ],
    priceAll: "Lorem ipsum",
    pricePer: "Lorem ipsum",
  }
];

export const online = [
  {
    cover: "./images/courses/online/ux_black.png",
    hoverCover: "./images/courses/online/o1.1.png",
    courseName: "Lorem ipsum",
    course: "Lorem ipsum",
  },
  {
    cover: "./images/courses/online/o2.png",
    hoverCover: "./images/courses/online/o2.1.png",
    courseName: "Lorem ipsum",
    course: "Lorem ipsum",
  },
  {
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Lorem ipsum",
    course: "Lorem ipsum",
  },
  {
    cover: "./images/courses/online/o4.png",
    hoverCover: "./images/courses/online/o4.1.png",
    courseName: "Lorem ipsum",
    course: "Lorem ipsum",
  },
  // {
  //   cover: "./images/courses/online/o5.png",
  //   hoverCover: "./images/courses/online/o5.1.png",
  //   courseName: "Lorem ipsum",
  //   course: "Lorem ipsum",
  // },
  // {
  //   cover: "./images/courses/online/o6.png",
  //   hoverCover: "./images/courses/online/o6.1.png",
  //   courseName: "Lorem ipsum",
  //   course: "Lorem ipsum",
  // },
  // {
  //   cover: "./images/courses/online/o7.png",
  //   hoverCover: "./images/courses/online/o7.1.png",
  //   courseName: "Lorem ipsum",
  //   course: "Lorem ipsum",
  // },
  // {
  //   cover: "./images/courses/online/o8.png",
  //   hoverCover: "./images/courses/online/o8.1.png",
  //   courseName: "Lorem ipsum",
  //   course: "Lorem ipsum",
  // },
  // {
  //   cover: "./images/courses/online/o9.png",
  //   hoverCover: "./images/courses/online/o9.1.png",
  //   courseName: "Lorem ipsum",
  //   course: "Lorem ipsum",
  // },
  // {
  //   cover: "./images/courses/online/o10.png",
  //   hoverCover: "./images/courses/online/o10.1.png",
  //   courseName: "Lorem ipsum",
  //   course: "Lorem ipsum",
  // },
  // {
  //   cover: "./images/courses/online/o11.png",
  //   hoverCover: "./images/courses/online/o11.1.png",
  //   courseName: "Lorem ipsum",
  //   course: "Lorem ipsum",
  // },
  // {
  //   cover: "./images/courses/online/o12.png",
  //   hoverCover: "./images/courses/online/o12.1.png",
  //   courseName: "Lorem ipsum",
  //   course: "Lorem ipsum",
  // },
];

export const team = [
  {
    cover: "./images/team/t1.webp",
    name: "Lorem ipsum",
    work: "Lorem ipsum",
  },
  {
    cover: "./images/team/t2.webp",
    name: "Lorem ipsum",
    work: "Lorem ipsum",
  },
  {
    cover: "./images/team/t3.webp",
    name: "Lorem ipsum",
    work: "Lorem ipsum",
  },
  {
    cover: "./images/team/t4.webp",
    name: "Lorem ipsum",
    work: "Lorem ipsum",
  },
  {
    cover: "./images/team/t5.webp",
    name: "Lorem ipsum",
    work: "Lorem ipsum",
  },
  {
    cover: "./images/team/t6.webp",
    name: "Lorem ipsum",
    work: "Lorem ipsum",
  },
  {
    cover: "./images/team/t7.webp",
    name: "Lorem ipsum",
    work: "Lorem ipsum",
  },
  {
    cover: "./images/team/t8.webp",
    name: "Lorem ipsum",
    work: "Lorem ipsum",
  },
];

export const price = [
  {
    name: "Lorem ipsum",
    price: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    name: "Lorem ipsum",
    price: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    name: "Lorem ipsum",
    price: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    name: "Lorem ipsum",
    price: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];

export const faq = [
  {
    title: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];

export const blog = [
  {
    id: 1,
    type: "admin",
    date: "Lorem ipsum",
    com: "Lorem ipsum",
    title: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    cover: "../images/blog/b1.webp",
  },
  {
    id: 2,
    type: "admin",
    date: "Lorem ipsum",
    com: "Lorem ipsum",
    title: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    cover: "../images/blog/b2.webp",
  },
  {
    id: 3,
    type: "user",
    date: "Lorem ipsum",
    com: "Lorem ipsum",
    title: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    cover: "../images/blog/b3.webp",
  },
  {
    id: 4,
    type: "admin",
    date: "Lorem ipsum",
    com: "Lorem ipsum",
    title: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    cover: "../images/blog/b4.webp",
  },
  {
    id: 5,
    type: "admin",
    date: "Lorem ipsum",
    com: "Lorem ipsum",
    title: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    cover: "../images/blog/b5.webp",
  },
  {
    id: 6,
    type: "user",
    date: "Lorem ipsum",
    com: "Lorem ipsum",
    title: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    cover: "../images/blog/b6.webp",
  },
];

export const testimonal = [
  {
    id: 1,
    name: "Lorem ipsum",
    post: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    cover: "./images/testo/t1.webp",
  },
  {
    id: 2,
    name: "Lorem ipsum",
    post: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    cover: "./images/testo/t2.webp",
  },
  {
    id: 3,
    name: "Lorem ipsum",
    post: "Lorem ipsum",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    cover: "./images/testo/t3.webp",
  },
];
