import StartupImage from "../../../../media/images/startup-4.jpeg";
import "./IntroSectionV1.css";
import Strie from "../../../../components/Strie/Strie";
import "./StriesAdjustments.css";
import useIntersectionObserver from "../../../../Hooks/useIntersectionObserver";
import LinkButton from "../../../../components/LinkButton/LinkButton";

export default function IntroSectionV1() {
  const [ref, isVisible] = useIntersectionObserver(0.3);
  return (
    <>
      <section ref={ref} className="algeria-intro-section-wrapper">
        <div className="algeria-intro-section-container">
          <div
            className={`algeria-intro-section-content ${
              isVisible ? "visible" : ""
            }`}
          >
            <h2 className="algeria-intro-section-title">
              Empowering Tunisian Startups to Thrive
            </h2>
            <div className="algeria-intro-section-description">
              The Algéria Startup Program is a comprehensive initiative designed
              to support and accelerate the growth of entrepreneurial ventures
              in Algéria. With a focus on web development, digital marketing,
              and other key business areas, we're committed to helping startups
              reach their full potential.
            </div>
            <div className="algeria-intro-section-buttons-container">
              {/* <div className="algeria-intro-section-button">Apply now</div> */}
              {/* <div className="algeria-intro-section-button">Learn more</div> */}
              <LinkButton to="/algeria/apply-now" text="S'inscrire" />
              <LinkButton
                to="/algeria/program-overview"
                text="En savoir plus"
                // hoverBgColor={
                //   "linear-gradient(to right, #6f483f, var(--primary-color-safety-orange))"
                // }
              />
            </div>
          </div>
          <div
            className={`algeria-intro-section-illustration ${
              isVisible ? "visible" : ""
            }`}
          >
            <img
              src={StartupImage}
              alt="Introduction section image"
              className="algeria-intro-section-image"
            />
          </div>
        </div>
        <Strie
          scale={5}
          color="green-green"
          top="10%"
          left="-5%"
          zindex={2}
          id="algeria-intro-section-top-left-strie-1"
        />
        <Strie
          scale={5}
          color="orange-africa"
          top="60%"
          left="100%"
          zindex={2}
          id="algeria-intro-section-bottom-right-strie-1"
        />
      </section>
    </>
  );
}
