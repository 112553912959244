import { useEffect, useRef, useState } from "react";
import { Parallax } from "react-parallax";
import Strie from "../../../../components/Strie/Strie";
import PlayIcon from "../../../../media/icons/PlayIcon";
import HaciendaImage from "../../../../media/images/pexels-jep-gambardella-7689739.jpg";
import "./VideoSection.css";

function VideoSection() {
  const dialogRef = useRef();
  const videoRef = useRef();

  /* animation d'apparition sur intersection */
  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  const openModal = () => {
    dialogRef.current?.showModal();
    document.body.style.overflow = "hidden";
    if (videoRef.current) {
      videoRef.current.contentWindow.postMessage(
        '{"event":"command","func":"playVideo","args":""}',
        "*"
      );
    }
  };

  const closeModal = () => {
    dialogRef.current?.close();
    document.body.style.overflow = "unset";
    if (videoRef.current) {
      videoRef.current.contentWindow.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        "*"
      );
    }
  };

  return (
    <div className="video-section-wrapper">
      <Parallax blur={2} bgImage={HaciendaImage} bgImageAlt="" strength={200}>
        <div
          ref={sectionRef}
          className={`video-section-container ${
            isSectionIntersected ? "visible" : ""
          }`}
        >
          <button className="video-section-button" onClick={openModal}>
            <PlayIcon width={64} height={64} color="white" />
          </button>
          <h2 className="semi-bold video-section-title">
            Nous vous poussons à l'excellence
          </h2>
          <dialog className="video-section-player" ref={dialogRef}>
            <iframe
              id="videoPlayer"
              ref={videoRef}
              width="100%"
              height="100%"
              // src="https://www.youtube.com/embed/vIypxZU3mpw?enablejsapi=1"
              src="https://www.youtube.com/embed/JQMRTb_mvDs?enablejsapi=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
            <button
              className="video-section-player-button"
              onClick={closeModal}
            >
              &#x2715;
            </button>
          </dialog>
        </div>
      </Parallax>
      <Strie scale={4} color="green-green" zindex={1} top="15%" />
      <Strie scale={1} color="brown" left="95%" top="48%" zindex={1} />
      <Strie scale={4} color="orange-africa" left="100%" top="54%" zindex={1} />
    </div>
  );
}

export default VideoSection;
