import "./InfoTabsSlider.css";

import Slider from "react-slick";

import img1 from "../../../../../media/images/business-man-1.jpg";
import img2 from "../../../../../media/images/business-man-1.jpg";
import img3 from "../../../../../media/images/happy-man-1.webp";
import NextIconV2 from "../../../../../media/icons/NextIconV2";
import PreviousIconV2 from "../../../../../media/icons/PreviousIconV2";
import ButtonV6 from "../../../../../components/ButtonV6/ButtonV6";
import { useState } from "react";

function SlideTextContent({ children }) {
  return <>{children}</>;
}

const slides = [
  {
    id: "1",
    name: "Image 1",
    alt: "",
    imageUrl: img1,
    btnUrl: "/",
    content: (
      <SlideTextContent>
        <p>
          <strong>Lorem ipsum dolor 1</strong> sit amet consectetur adipisicing
          elit. Nostrum vero fuga, doloremque eum tenetur, accusamus minus
          doloribus animi aperiam quaerat, excepturi asperiores laboriosam amet
          nisi atque quo distinctio unde suscipit?{" "}
        </p>
      </SlideTextContent>
    ),
  },
  {
    id: "2",
    name: "Image 2",
    alt: "",
    imageUrl: img2,
    btnUrl: "/",
    content: (
      <SlideTextContent>
        <p>
          <strong>Lorem ipsum dolor 2</strong> sit amet consectetur adipisicing
          elit. Nostrum vero fuga, doloremque eum tenetur excepturi asperiores
          laboriosam amet nisi atque quo distinctio, accusamus minus doloribus
          animi aperiam quaerat, excepturi asperiores laboriosam amet nisi atque
          quo distinctio unde suscipit?{" "}
        </p>
      </SlideTextContent>
    ),
  },
  {
    id: "3",
    name: "Image 3",
    alt: "",
    imageUrl: img3,
    btnUrl: "/",
    content: (
      <SlideTextContent>
        <p>
          <strong>Lorem ipsum dolor 3</strong> accusamus minus doloribus animi
          aperiam quaerat, excepturi asperiores laboriosam amet nisi atque quo
          distinctio unde suscipit?{" "}
        </p>
      </SlideTextContent>
    ),
  },
];

const CustomPreviousArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      onClick={onClick}
      className={`carousel-3-btn carousel-3-next-btn ${className}`}
      type="button"
    >
      <PreviousIconV2 width={16} height={16} />
    </button>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      onClick={onClick}
      className={`carousel-3-btn carousel-3-next-btn ${className}`}
      type="button"
    >
      <NextIconV2 width={16} height={16} />
    </button>
  );
};

export default function InfoTabsSlider() {
  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slides",
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPreviousArrow />,
    beforeChange: (current, next) => setActiveSlide(next),
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slides.map((slide) => {
          return (
            <div className="wrapper" key={slide.id}>
              <img className="sliderImg" src={slide.imageUrl} alt={slide.alt} />
            </div>
          );
        })}
      </Slider>
      <div className="slider-content-container">
        <div className="slider-content">{slides[activeSlide]?.content}</div>
        <div className="slider-content-description-btn-container">
          <ButtonV6
            btnText="En savoir plus"
            linkUrl={slides[activeSlide]?.btnUrl}
          />
        </div>
      </div>
    </div>
  );
}
