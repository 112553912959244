import "./TestimonialCardV2.css";
import useIntersectionObserver from "../../Hooks/useIntersectionObserver";

export default function TestimonialCardV2({
  id = "",
  personName,
  companyName,
  children,
}) {
  const [ref, isVisible] = useIntersectionObserver(0.7);

  return (
    <>
      <div
        ref={ref}
        className={`testimonial-card-2-wrapper ${isVisible ? "visible" : ""}`}
        id={`testimonial-card-2${id ? "-" + id : ""}`}
      >
        <div className="testimonial-card-2-container">
          <div className="testimonial-card-2-body">" {children} "</div>
          <div className="testimonial-card-2-header">
            <PersonIcon width={48} height={48} />
            <div className="testimonial-card-2-client-info">
              <div className="testimonial-card-2-client-info-name">
                {personName}
              </div>
              <div className="testimonial-card-2-client-info-company-name">
                Founder, {companyName}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function PersonIcon({ width = 24, height = 24 }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        width={width}
        height={height}
        viewBox="0 0 32 32"
        style={{ minWidth: width, minHeight: height }}
      >
        <title />
        <g id="about">
          <path d="M16,16A7,7,0,1,0,9,9,7,7,0,0,0,16,16ZM16,4a5,5,0,1,1-5,5A5,5,0,0,1,16,4Z" />
          <path d="M17,18H15A11,11,0,0,0,4,29a1,1,0,0,0,1,1H27a1,1,0,0,0,1-1A11,11,0,0,0,17,18ZM6.06,28A9,9,0,0,1,15,20h2a9,9,0,0,1,8.94,8Z" />
        </g>
      </svg>
    </>
  );
}
