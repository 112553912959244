import "./LinkButton.css";
import { Link } from "react-router-dom";

export default function LinkButton({
  text,
  to,
  color,
  bgColor,
  hoverColor,
  hoverBgColor,
}) {
  return (
    <>
      <Link
        to={to}
        className="animated-button-1"
        style={{
          "--bg-color": bgColor,
          "--hover-bg-color": hoverBgColor,
          "--color": color,
          "--hover-color": hoverColor,
        }}
      >
        <ArrowIcon className={"arr-2"} />
        <span class="text">{text}</span>
        <span class="circle"></span>
        <ArrowIcon className={"arr-1"} />
      </Link>
    </>
  );
}

function ArrowIcon({ className: additionalClassNames }) {
  const classNames = `arrow-icon ${additionalClassNames}`;
  return (
    <>
      <svg
        viewBox="0 0 24 24"
        className={classNames}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
      </svg>
    </>
  );
}
