import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Parallax } from "react-parallax";
import { Link } from "react-scroll";
import "./HeroSectionV2.css";

// import HeroBackground from "../../../../media/images/hero_image.jpg";
// import HeroBackground from "../../../../media/images/hero_image_2.jpg";
// import HeroBackground from "../../../../media/images/hero_image_3.webp";
// import HeroBackground from "../../../../media/images/hero_image_4.png";
// import HeroBackground from "../../../../media/images/hero_image_5.webp";
// import HeroBackground from "../../../../media/images/hero_image_6.jpeg";
// import HeroBackground from "../../../../media/images/hero_image_7.jpeg";
// import HeroBackground from "../../../../media/images/hero_image_8.jpeg";
// import HeroBackground from "../../../../media/images/hero_image_9_1920x1280.webp";
import HeroBackground from "../../../../media/images/hero_image_9_6000x4000.webp";
// import HeroBackground from "../../../../media/images/hero_image_.jpeg";
// import HeroBackgroundSmall from "../../../../media/images/hero_image.jpg";
// import HeroBackgroundSmall from "../../../../media/images/hero_image_8_small_720w.jpg";
import HeroBackgroundSmall from "../../../../media/images/hero_image_9_1600x1067.jpg";

export default function HeroSectionV2() {
  /*animation d'apparition sur intersection*/
  const { t } = useTranslation();

  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      console.log("resizing");
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const backgroundImage =
    windowWidth > 720 ? HeroBackground : HeroBackgroundSmall;

  console.log("backgroundImage", backgroundImage);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  });

  return (
    <>
      <Parallax
        blur={0}
        bgImage={backgroundImage}
        bgImageStyle={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
        bgImageAlt="Hero Section"
        strength={400}
        className={`parallax-image-container ${
          isSectionIntersected ? "visible" : ""
        }`}
        style={{ transition: "all 2s ease-in-out" }}
      >
        <div
          className={`hero-section-wrapper ${
            isSectionIntersected ? "visible" : ""
          }`}
        >
          <div
            ref={sectionRef}
            className={`hero-section-container ${
              isSectionIntersected ? "visible" : ""
            }`}
          >
            <div
              className={`hero-section-body ${
                isSectionIntersected ? "visible" : ""
              }`}
            >
              <h1 className="hero-section-title bold">{t("heroTitle")}</h1>
              <h6 className="hero-section-slogan">{t("heroSlogan")}</h6>
              <Link
                to="/#our-trainings-section"
                spy={true}
                smooth={true}
                offset={-70} // Adjust this offset as needed to fit your layout
                duration={500}
                className="hero-section-cta"
              >
                <span>{t("heroCTA")}</span>
                {/* <IconNext rotation="rotate-down" width={22} height={22} /> */}
              </Link>
            </div>
          </div>
        </div>
      </Parallax>
    </>
  );
}
