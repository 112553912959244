import "./TunisiaPage.css";

import Footer from "../../components/Footer/Footer";
import ContentSection1 from "./sections/ContentSection1/ContentSection1";
import ContentSection2 from "./sections/ContentSection2/ContentSection2";
import InfoTabsSection from "./sections/InfoTabsSection/InfoTabsSection";
import IntroSection from "./sections/IntroSection/IntroSection";
import ProgramFeatures from "./sections/ProgramFeatures/ProgramFeatures";
import ProgramOverview from "./sections/ProgramOverview/ProgramOverview";
import Testimonials from "./sections/testimonials/TestimonialsSection";
import TrainingsSection from "./sections/TrainingsSection/TrainingsSection";
import TunisiaHeroSection from "./sections/TunisiaHeroSection/TunisiaHeroSection";

export default function TunisiaPage() {
  return (
    <>
      <div className="tunisia-page-wrapper">
        ,,,,,
        <TunisiaHeroSection />
        <IntroSection />
        <InfoTabsSection />
        <ContentSection1 />
        <ContentSection2 />
        <ProgramOverview />
        <TrainingsSection />
        <Testimonials />
        <ProgramFeatures />
      
      </div>
    </>
  );
}
