import React from "react"

import Hero from "./hero/Hero"

import Testimonal from "./testimonal/Testimonal"

const Home = () => {
  return (
    <>
      <Hero />

      {/* <HAbout /> */}

      {/* <Hblog /> */}
      {/* <Hprice /> */}
    </>
  )
}

export default Home
