import Footer from "../../components/Footer/Footer";
import pageData from "../pageData.json";

import InfoTabsSectionV2 from "../Algeria/sections/InfoTabsSectionV2/InfoTabsSectionV2";
import "./MoroccoPage.css";
import MoroccoHeroSection from "./sections/MoroccoHeroSection/MoroccoHeroSection";
export default function MoroccoPage() {
  const { features, sources } = pageData.pageMaroc;

  return (
    <>
      <MoroccoHeroSection />
      <InfoTabsSectionV2 features={features} sources={sources} />

   
    </>
  );
}
