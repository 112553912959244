import { Route, Routes } from "react-router-dom";
import "./App.css";
import AlgeriaPage from "./pages/Algeria/AlgeriaPage";
import CartPage from "./pages/cartPage/cartPage";
import GuineaConakryPage from "./pages/GuineaConakry/GuineaConakryPage";
import HomePage from "./pages/homepage/HomePage";
import IvoryCoastPage from "./pages/IvoryCoast/IvoryCoastPage";
import MaliPage from "./pages/Mali/MaliPage";
import MoroccoPage from "./pages/Morocco/MoroccoPage";
import NosFormation from "./pages/NosFormation/NosFormation";
import SenegalPage from "./pages/Senegal/SenegalPage";
import TunisiaPage from "./pages/Tunisia/TunisiaPage";
import Validation from "./pages/PaymentConfirmation.jsx/Validation";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/cartPage" element={<CartPage />} />
        <Route path="/validation" element={<Validation />} />

        <Route path="/NosFormation" element={<NosFormation />} />
        <Route path="/formations">
          <Route path="algerie" element={<AlgeriaPage />} />
          <Route path="tunisie" element={<TunisiaPage />} />
          <Route path="maroc" element={<MoroccoPage />} />
          <Route path="cote-d-ivoire" element={<IvoryCoastPage />} />
          <Route path="mali" element={<MaliPage />} />
          <Route path="guinee-conakry" element={<GuineaConakryPage />} />
          <Route path="senegal" element={<SenegalPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
