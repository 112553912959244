import React, { useState, useEffect } from "react";
import "./Section.css";
import sectionData from "./sectionData.json";

// Créer un contexte pour charger les images dynamiquement
const importAll = (requireContext) => requireContext.keys().reduce((acc, next) => {
  acc[next.replace('./', '')] = requireContext(next);
  return acc;
}, {});

const images = importAll(require.context("../../../media/images", false, /\.(png|jpe?g|svg)$/));

export default function Section() {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Charger les données directement depuis le fichier JSON
    setData(sectionData[0]);  // Utilisez la première section pour cet exemple
  }, []);
  if (!data) return <div>Loading...</div>;

  return (
    <section className="section-template-one-mali">
      <div className="section-template-one-container-mali">
        <div className="section-template-one-content-mali">
          <div className="section-template-one-text-mali">
            <h2 className="section-template-one-title-mali">
              {data.title}
            </h2>
            <p className="section-template-one-description-mali">
              {data.description}
            </p>
          </div>
          <div className="section-template-one-features-mali">
            {data.features.map((feature, index) => {
              const IconComponent = icons[feature.icon];
              return (
                <FeatureItem
                  key={index}
                  icon={IconComponent}
                  title={feature.title}
                  description={feature.description}
                />
              );
            })}
          </div>
        </div>
        <div className="section-template-one-illustration-mali">
          <img
            src={images[data.image]}
            alt="Hero"
            className="section-template-one-image-mali"
          />
        </div>
      </div>
    </section>
  );
}

function FeatureItem({ icon: Icon, title, description }) {
  return (
    <div className="feature-item-mali">
      <div className="feature-icon-mali">
        <Icon className="icon-mali" />
      </div>
      <div>
        <h6 className="feature-title-mali">{title}</h6>
        <p className="feature-description-mali">{description}</p>
      </div>
    </div>
  );
}

const icons = {
  BriefcaseIcon: (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16 20V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
      <rect width="20" height="14" x="2" y="6" rx="2" />
    </svg>
  ),
  RocketIcon: (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z" />
      <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z" />
      <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0" />
      <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5" />
    </svg>
  ),
  LightbulbIcon: (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15 14c.2-1 .7-1.7 1.5-2.5 1-.9 1.5-2.2 1.5-3.5A6 6 0 0 0 6 8c0 1 .2 2.2 1.5 3.5.7.7 1.3 1.5 1.5 2.5" />
      <path d="M9 18h6" />
      <path d="M10 22h4" />
    </svg>
  ),
};
