import React, { useEffect, useState } from "react";
import LocalStorage from "react-secure-storage";
import useIntersectionObserver from "../../Hooks/useIntersectionObserver";
import ButtonV6 from "../ButtonV6/ButtonV6";
import Modal from "../Modal/Modal";
import "./TrainingCardV2.css";

export default function TrainingCardV2({
  id,
  title,
  Image,
  durer,
  date,
  ctaBtnText,
  children,
  itemColor = "currentColor",
  btnHoverFillColor = "#FFD700",
  modalData,
}) {
  const [showModal, setShowModal] = useState(false);
  //const [showPayPalButton, setShowPayPalButton] = useState(false);
  const [isInCart, setIsInCart] = useState(false); // État pour gérer l'ajout/retrait du panier
  const [cart, setCart] = useState(
    LocalStorage.getItem("training-card")
      ? JSON.parse(LocalStorage.getItem("training-card"))
      : []
  );

  useEffect(() => {
    LocalStorage.setItem("training-card", JSON.stringify(cart));
    console.log("Cart Updated:", cart);
  }, [cart]);

  const [ref, isVisible] = useIntersectionObserver(0.4);

  useEffect(() => {
    // Vérifie si cet élément est déjà dans le panier lors du montage
    const isItemInCart = cart.some((item) => item.id === id);
    console.log("Vérification initiale du panier pour", id, ": ", isItemInCart);
    setIsInCart(isItemInCart);
  }, [cart, id]);

  const handleCartClick = () => {
    let updatedCart;

    // Vérifie si l'article est dans le panier
    const isItemInCart = cart.some((item) => item.id === id);

    if (isItemInCart) {
      // Retirer du panier
      updatedCart = cart.filter((item) => item.id !== id);
      console.log("Retirer du panier:", updatedCart);
    } else {
      // Ajouter au panier
      const newItem = {
        id: id,

        title: title,
        Image: Image,
        prix: modalData.prix,
        durer: durer,
        date: date,
        date_choisi: date[0],
      };
      updatedCart = [...cart, newItem];
      console.log("Ajouter au panier:", newItem);
    }

    setCart(updatedCart);
    LocalStorage.setItem("training-card", JSON.stringify(updatedCart));

    // Met à jour l'état isInCart en fonction de la présence de l'article dans le panier
    setIsInCart(!isItemInCart);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  /*const handlePayPalClick = () => {
    setShowPayPalButton(true);
  };*/

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = modalData.downloadLink;
    link.setAttribute("download", "file.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div
        ref={ref}
        className={`training-card-2-container ${isVisible ? "visible" : ""}`}
        id={`training-card-${id}`}
      >
        <h6 className="training-card-2-title">{title}</h6>
        <div>
          <img
            src={require(`../../media/images/${Image}`)} // Correction de l'import d'image
            alt={title}
            className={`tab-content-image info-tabs-section-image ${
              isVisible ? "visible" : ""
            }`}
          />
        </div>

        <div className="training-card-2-description">{children}</div>

        <div className="training-card-2-cta-btn-container">
          <ButtonV6
            btnText={ctaBtnText}
            onClick={toggleModal}
            hoverFillColor={btnHoverFillColor}
          />
          <ButtonV6
            onClick={handleCartClick}
            btnText={isInCart ? "Retirer du Panier" : "Ajouter Au panier"}
          />
        </div>
      </div>

      <Modal show={showModal} onClose={toggleModal}>
        <div className="scrollable-container">
          <div className="scrollable-content">
            <h2>{title}</h2>
            <div>
              <img
                src={require(`../../media/images/${Image}`)} // Correction de l'import d'image
                alt={title}
                className={`tab-content-image info-tabs-section-image ${
                  isVisible ? "visible" : ""
                }`}
              />
            </div>

            <div>
              <div style={{ textAlign: "left", marginTop: "20px" }}>
                <h3>Programme</h3>

                <ul>
                  {modalData.programme.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div>
              <div style={{ textAlign: "left", marginTop: "20px" }}>
                <h3>Programme</h3>
                <ul>
                  {modalData.programme.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div>
              <div style={{ textAlign: "left", marginTop: "20px" }}>
                <h3>Programme</h3>
                <ul>
                  {modalData.programme.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>

            <h4>Prix :{modalData?.prix?.toLocaleString("fr-FR")} €</h4>

            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div className="training-card-2-modal-download">
                <a href={`tel:${modalData.contactNumber}`}>
                  <button>Contacter un conseiller</button>
                </a>
              </div>

              <div className="training-card-2-modal-download">
                <button onClick={handleDownload}>
                  Télécharger le programe
                </button>
              </div>

              <div>
                {/*<div className="training-card-2-modal-download">
                  <button onClick={handlePayPalClick}>
                    Accéder au paiement
                  </button>
                </div>

                {showPayPalButton && (
                  <div
                    className="paypal-button-container"
                    style={{ marginTop: "20px" }}
                  >
                    <PayPalButtons
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                value: modalData.price.toString(),
                              },
                            },
                          ],
                        });
                      }}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                          alert(
                            `Transaction effectuée par ${details.payer.name.given_name}`
                          );
                        });
                      }}
                    />
                  </div>
                )}*/}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
