export default function PreviousIconV2({
  width = 24,
  height = 24,
  color = "currentColor",
}) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        fill={color}
        height={height}
        width={width}
        style={{ minHeight: height, minWidth: width }}
        viewBox="0 0 1000 1000"
      >
        <g
          transform="matrix(-16.6301 0 0 -16.6301 499.0006 492.2367)"
          id="783423"
        >
          <path
            vectorEffect="non-scaling-stroke"
            transform=" translate(-27.8762, -27.8762)"
            d="M 43.006 23.916 c -0.28 -0.282 -0.59 -0.52 -0.912 -0.727 L 20.485 1.581 c -2.109 -2.107 -5.527 -2.108 -7.637 0.001 c -2.109 2.108 -2.109 5.527 0 7.637 l 18.611 18.609 L 12.754 46.535 c -2.11 2.107 -2.11 5.527 0 7.637 c 1.055 1.053 2.436 1.58 3.817 1.58 s 2.765 -0.527 3.817 -1.582 l 21.706 -21.703 c 0.322 -0.207 0.631 -0.444 0.912 -0.727 c 1.08 -1.08 1.598 -2.498 1.574 -3.912 C 44.605 26.413 44.086 24.993 43.006 23.916 z"
            strokeLinecap="round"
          />
        </g>
      </svg>
    </>
  );
}
